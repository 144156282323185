import React from 'react';
import {Row} from "react-bootstrap";
import {motion} from "framer-motion";
import Collection from "../collection";


type Props = {
    currentAccount?: string;
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,       
}

const Component: React.FC<Props> = ({currentAccount, setCurrentAccount, token, setToken}) => {

    return (
        <div className="mainpage-wrapper-container home-second-container pb-5 overflow-hidden">
            <Row className="mt-5">
                <motion.h1
                    initial={{ y: -80, scale: 0.5 }}
                    whileInView={{ y: 0, scale: 1 }}
                    transition={{
                        type: 'circIn',
                        ease: 'anticipate',
                        duration: 1
                    }}
                    className="text-center">Our Collection
                </motion.h1>
                <Collection setCurrentAccount={setCurrentAccount}
                            currentAccount={currentAccount} 
                            setToken={setToken}
                            token={token} 
                            />
            </Row>
        </div>
    )
}

export default Component;