import React, {useState} from 'react';
import {useContract} from "../../hooks/useContracts";
import NumberInput from '../../components/util/number-input'
import {motion} from "framer-motion";
import {BallTriangle} from 'react-loader-spinner'
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import {checkAccountConnect} from "../../api/api-service";
import { toast } from "react-toastify";

import "./styles.scss"
import {
    buyNtf,
    clearCacheProvider,
    connectWallet,
    getCurrentAccount,
} from "../../network/ethereum";

type Props = {
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

type CartItem = {
    tokenId: string,
    amount: number
}

const Collection: React.FC<Props> = ({currentAccount, setCurrentAccount, token, setToken}) => {

    const [error, setError] = useState(false);
    const [cart, setCart] = useState<CartItem[]>([]);
    const {tokenMetadataList, loading, fetchTokenList} = useContract(currentAccount);

    const onChangeQuantity = (tokenId: string, amount: number) => {
        if (cart.filter(item => item.tokenId === tokenId).length === 0) {
            let newCart = cart;
            newCart.push({
                tokenId,
                amount
            });
            setCart(newCart);
            return;
        }
        let newCart = cart;
        let cartItem = newCart.filter(item => item.tokenId === tokenId)[0];
        cartItem.amount = amount;
        setCart(newCart);
    }

    const onBuyClick = async () => {
        setError(false);

        if (!currentAccount) {
            toast.info("Please connect your wallet.");
            return;
        }
        let quantity = cart.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);

        if (quantity === 0) {
            toast.info("Pick the bear you'd like to mint.");
            return;
        }

        if (quantity > 5) {
            toast.info("You cannot mint more than 5 bears.");
            return;
        }

        let loadingId = toast.loading("Minting your bear. Hang tight!");

        try {
            let tokenIds = cart.map(item => item.tokenId);
            let amounts = cart.map(item => item.amount);
            await buyNtf(tokenIds, amounts);
            setCart([]);
            await fetchTokenList(currentAccount);
            toast.update(loadingId, { render: "Successfully Minted", type: "success", isLoading: false, autoClose: 5000 });
        } catch (e) {
            toast.dismiss();
            setError(true);
        }
    }

    const getCurrentAmount = (tokenId: string) => {
        if (cart.filter(item => item.tokenId === tokenId).length === 0) {
            return 0;
        }
        let cartItem = cart.filter(item => item.tokenId === tokenId)[0];
        return cartItem.amount;
    }

    const connect = async () => {
        try {
            let provider = await connectWallet();
            if (provider.on) {
                provider.on("accountsChanged", async (accounts: any) => {
                    if (accounts.length === 0) {
                        clearCacheProvider();
                        setCurrentAccount(undefined);
                    }
                });
            }
            const account = await getCurrentAccount();
            const connectedAccount = await checkAccountConnect(account);
            setCurrentAccount(account);
            setToken(connectedAccount?.data?.token);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Container className="mt-5 ntf-collection-section">
                <Row className="justify-content-center">

                    {loading &&
                        <Col md={12} className="text-center">
                            <BallTriangle wrapperClass="d-inline-flex" color="#eb6864" height={80} width={80}/>
                        </Col>
                    }

                    {!loading && tokenMetadataList.length === 0 &&
                        <Col className="text-center">
                            <Alert variant="warning">You don't have any token yet!</Alert>
                        </Col>
                    }

                    {!loading && tokenMetadataList && tokenMetadataList.length > 0 &&
                        tokenMetadataList.map((tokenMetadata, index) => (
                            <Col key={index} lg={4} md={6} sm={12}>
                                <motion.div
                                    className="text-center m-lg-2">
                                    <Row>
                                        <motion.img
                                            initial={{
                                                x: 0,
                                                rotate: 5,
                                            }}
                                            whileInView={{
                                                x: 0,
                                                rotate: 0,
                                            }}
                                            transition={{
                                                type: 'spring',
                                                stiffness: 100,
                                                mass: 3,
                                                damping: 1,
                                            }}
                                            className="img-thumbnail"
                                            src={tokenMetadata.image}
                                        />
                                    </Row>
                                    <Row className="mt-3">
                                        <h4 className="token-name">{tokenMetadata.name}</h4>
                                    </Row>
                                    <Row>
                                        {/*<h5 className="text-center h6 token-supply">*/}
                                        {/*    Total Supply : {tokenMetadata.maxTotalSupply}*/}
                                        {/*</h5>*/}
                                        {<h5 className="text-center h4 token-price">
                                            0.3 ETH
                                        </h5>}
                                    </Row>
                                    <Row className="mt-2 mb-5">
                                        <NumberInput
                                            onChangeValue={(value) => onChangeQuantity(tokenMetadata.tokenId, value)}
                                            value={getCurrentAmount(tokenMetadata.tokenId)}/>
                                    </Row>
                                </motion.div>
                            </Col>
                        ))
                    }
                </Row>
                <Row className="justify-content-center mt-5">
                    {!loading && currentAccount  && <Col className="text-center">
                        <motion.button whileHover={{ scale: 1.2 }} className="btn btn-mint" onClick={() => onBuyClick()}>MINT YOUR BEARS</motion.button>
                    </Col>}
                    {!loading && !currentAccount &&
                        <Col className="text-center">
                            <Button className="btn-rounded wallet-connect-button" variant="flat"
                                    onClick={() => connect()}>
                                Connect To Wallet
                            </Button>
                        </Col>
                    }
                </Row>
            </Container>
        </>
    )
}

export default Collection;