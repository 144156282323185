import React from 'react';
import {Container} from "react-bootstrap";
import {motion} from "framer-motion";

import team1 from '../../images/team/Team1.jpg';
import team2 from '../../images/team/Team2.jpg';
import team3 from '../../images/team/Team3.jpg';
import team4 from '../../images/team/Team4.jpg';


type Props = {}

const Component: React.FC<Props> = () => {

    return (
        <div className="our-team-container pt-5 pb-5 overflow-hidden">
            <Container>
                <motion.h1
                    initial={{ y: -50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        type: 'circIn',
                        ease: 'anticipate',
                        duration: 1
                    }}
                    className="text-center mb-5 text-black">
                    Team Leads
                </motion.h1>
                <div className="row">
                    <motion.div
                        initial={{ y: -100 }}
                        whileInView={{ y: 0 }}
                        transition={{ duration: 0.5, delay: 0 }}
                        className="col-6 col-sm-4 col-md-3 i pb-4">
                        <div className="c text-center">
                            <div className="wrap">
                                <img src={team1} className="img-responsive" alt="Khan" />
                                <div className="info">
                                    <h3 className="name">Khan</h3>
                                    <h4 className="position">Founder<br/>KicksOnFire & Kixify</h4>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ y: -100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="col-6 col-sm-4 col-md-3 i pb-4">
                        <div className="c text-center">
                            <div className="wrap">
                                <img src={team2} className="img-responsive" alt="Sal" />
                                <div className="info">
                                    <h3 className="name">Sal</h3>
                                    <h4 className="position">Software Engineer<br/>Amazon</h4>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ y: -100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="col-6 col-sm-4 col-md-3 i pb-4">
                        <div className="c text-center">
                            <div className="wrap">
                                <img src={team3} className="img-responsive" alt="Sajith" />
                                <div className="info">
                                    <h3 className="name">Sajith</h3>
                                    <h4 className="position">Blockchain Specialist<br/>KicksOnFire</h4>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ y: -100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                        className="col-6 col-sm-4 col-md-3 i pb-4">
                        <div className="c text-center">
                            <div className="wrap">
                                <img src={team4} className="img-responsive" alt="Sagar" />
                                <div className="info">
                                    <h3 className="name">Sagar</h3>
                                    <h4 className="position">iOS Engineer<br/>KicksOnFire & Kixify</h4>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </Container>
        </div>

    )
}

export default Component;