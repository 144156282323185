import React from 'react';
import {Container, Row} from "react-bootstrap";
import {motion} from "framer-motion";

type Props = {
}

const Component: React.FC<Props> = () => {

    return (
        <div className="road-map-container overflow-hidden">
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <Container>
                <Row className="mt-5 mb-5">
                    <motion.h1
                        initial={{ y: -80, scale: 0.5 }}
                        whileInView={{ y: 0, scale: 1 }}
                        transition={{
                            type: 'circIn',
                            ease: 'anticipate',
                            duration: 1
                        }}
                        className="text-center mb-2">Road Map</motion.h1>

                    <p className="text-white text-center mb-5">Here's a brief overview of the roadmap ahead of us. The most reliable way to predict the future is to create it.</p>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-timeline">
                                <motion.div
                                    initial={{ x: -100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content">
                                        <h5 className="progress-text">0% Progress</h5>
                                        <h4 className="title">Bears Club Journey Begins</h4>
                                        <span className="date">Presale: Feb. 23, 2022</span>
                                        <p className="description">
                                            5,555 NFTs go live. 5 unique bear design NFTs inspired by some of the most memorable basketball moments involving our favorite greatest of all-time players.
                                            Each design will be available to mint 1,111 times on the ETH ERC-1155 blockchain.
                                        </p>
                                    </div>
                                </motion.div>

                                <motion.div
                                    initial={{ x: 100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }} className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content right">
                                        <h5 className="progress-text">15% Progress</h5>
                                        <h4 className="title">Rewards for the OGs</h4>
                                        <span className="date">Est. Completion: Mar. 31, 2022*</span>
                                        <p className="description">
                                            Time to give back to our Day 1s. We’ll be giving away $25,000 in Sneakers to NFT Holders commemorating a successful launch by our community.
                                            The community will decide how the funds are allocated. Open to donating part of the proceeds.
                                        </p>
                                    </div>
                                </motion.div>

                                <motion.div
                                    initial={{ x: -100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }} className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content">
                                        <h5 className="progress-text">30% Progress</h5>
                                        <h4 className="title">Merch Goes Live</h4>
                                        <span className="date">Est. Completion: April 30, 2022*</span>
                                        <p className="description">
                                            We’re launching a full catalog of T-Shirts, Hoodies and more. NFT Holders get early access and 50% OFF all items.
                                            Time to rock your NFT with pride.
                                        </p>
                                    </div>
                                </motion.div>

                                <motion.div
                                    initial={{ x: 100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }} className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content right">
                                        <h5 className="progress-text">45% Progress</h5>
                                        <h4 className="title">iOS App Launch on App Store</h4>
                                        <span className="date">Est. Completion: May 31, 2022*</span>
                                        <p className="description">
                                            Our marketplace ecosystem is launched. NFT holders are granted 0% fees for buying and selling.
                                            This lays down the foundation of peer-to-peer and direct vendor to buyer infrastructure to eliminate the middleman.
                                        </p>
                                    </div>
                                </motion.div>

                                <motion.div
                                    initial={{ x: -100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }} className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content">
                                        <h5 className="progress-text">60% Progress</h5>
                                        <h4 className="title">Members Only Portal Goes Live</h4>
                                        <span className="date">Est. Completion: June 30, 2022*</span>
                                        <p className="description">
                                            After eliminating the middleman, our NFT holder portal will give users access to sneaker inventory across the world for up to 10-20% lower
                                            prices as compared to marketplaces such as Goat, Stockx, Flightclub & Stadium Goods.
                                        </p>
                                    </div>
                                </motion.div>

                                <motion.div
                                    initial={{ x: 100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }} className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content right">
                                        <h5 className="progress-text">75% Progress</h5>
                                        <h4 className="title">Reward the Diamond Hands</h4>
                                        <span className="date">Est. Completion: July 30, 2022*</span>
                                        <p className="description">
                                            Time to give back to our diamond hands holders. We’ll be giving away $25,000 in Sneakers to NFT Holders commemorating a successful execution of our roadmap.
                                            The community will decide how the funds are allocated. Open to donating part of the proceeds.
                                        </p>
                                    </div>
                                </motion.div>

                                <motion.div
                                    initial={{ x: -100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }} className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content">
                                        <h5 className="progress-text">90% Progress</h5>
                                        <h4 className="title">Retail Drops Begin for NFT Holders</h4>
                                        <span className="date">Est. Completion: Aug. 31, 2022*</span>
                                        <p className="description">
                                            With the roadmap coming to completion and through the relationships with our vendors, we will now start dropping sneakers at near retail prices monthly.
                                            These drops will be accessible to holders through the Members Only portal.
                                        </p>
                                    </div>
                                </motion.div>

                                <motion.div
                                    initial={{ x: 100 }}
                                    whileInView={{ x: 0 }}
                                    transition={{ duration: 0.5 }} className="timeline">
                                    <div className="timeline-icon"/>
                                    <div className="timeline-content right">
                                        <h5 className="progress-text">100% Progress</h5>
                                        <h4 className="title">Time to Dive into the Metaverse</h4>
                                        <span className="date">Est. Completion: TBD*</span>
                                        <p className="description">
                                            It’s time to take our sneakers into the metaverse. We’ll start building a team of 3D animators and engineers to bring sneakers into the metaverse for our community and holders.
                                            We will involve all holders to vote on how to allocate funds to make a MetaVerse purchase, (Likely in Decentraland) where we will be building a basketball court or sneaker store
                                            for our community.
                                        </p>
                                    </div>
                                </motion.div>

                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default Component;