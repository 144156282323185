import {useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import Home from "./components/home";
import Header from "./components/header";
import Footer from "./components/footer";
import AccessCode from "./components/access-code";
import ReleaseList from "./components/release/list";
import ReleaseView from "./components/release/view";
import OrderList from "./components/order/list";
import OrderView from "./components/order/view";
import Complete from "./components/order/complete";

import { useWallet } from "./hooks/useWallet";

const App =()=> {
    const {currentAccount, setCurrentAccount, token, setToken} = useWallet();
    const [api, setApi] = useState<string | undefined>("");
    return (
            <BrowserRouter>
                <Header
                    currentAccount={currentAccount}
                    setCurrentAccount={setCurrentAccount}
                    token={token}
                    setToken={setToken}                    
                />
                <ToastContainer position="bottom-center"
                                autoClose={5000}
                                className="site-toaster"
                                theme="dark"
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable={true}
                                pauseOnHover={false}/>
                <Routes>
                    <Route path='/' element={<Home 
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        token={token}
                        setToken={setToken} />}/>
                    <Route path='/access-code' element={<AccessCode/>}/>
                    <Route path='/releases' key='shop' element={<ReleaseList 
                        api='shop'
                        setApi={setApi}
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        token={token}
                        setToken={setToken} />}/>
                    <Route path='/near-retail' key='near_retail'  element={<ReleaseList 
                        api='near_retail'
                        setApi={setApi}
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        token={token}
                        setToken={setToken} />}/>
                    <Route path='/release/:slug' element={<ReleaseView 
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        token={token}
                        setToken={setToken} />}/>
                    <Route path='/orders' element={<OrderList 
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        token={token}
                        setToken={setToken} />}/>
                    <Route path='/order/:orderId' element={<OrderView 
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        token={token}
                        setToken={setToken} />}/>
                   <Route path='/order/:orderId/complete' element={<Complete 
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        token={token}
                        setToken={setToken} />}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
    );
}

export default App;