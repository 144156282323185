import axios from "axios";
import {BaseApiResponse, AXIOS_CONFIG} from "./api-constants";
import {Size, Address, Tax, ValidAddress, Country, Province, FieldError, Product} from "../models"



interface EstimateDeliveryResponse extends BaseApiResponse {
    data: {
        estimated_delivery:string,
    },
}

interface EstimateTaxResponse extends BaseApiResponse {
    data: {
        tax: Tax,
        valid_address: ValidAddress
    },
}

interface CheckoutResponse extends BaseApiResponse {
    data: {
        url?: string,
        post_data?:Object,
        paypal_type?: string,
    },
    errors: Array<FieldError>
}

interface CountriesResponse extends BaseApiResponse {
    data: {
        items: Array<Country>,
    },
}
interface ProvincesResponse extends BaseApiResponse {
    data: {
        items: Array<Province>,
    },
}

export const EstimateDeliveryApi =  async (nid:number, location:Object = {}, token?:string): Promise<EstimateDeliveryResponse> =>  {
    const url = `/api/settings/estimate_delivery`;
    const requestConfig = Object.assign({},AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<EstimateDeliveryResponse>(url, {
        nid: nid, 
        location: location
    });

    if (response.status === 404) {
       throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        throw new Error("Unable to load release lists.");
    }
    return response.data;
}

export const EstimateTaxApi =  async(item: Size, shippingAddress:Address = {}, token?: string): Promise<EstimateTaxResponse> =>  {
    const url = `/api/settings/taxes`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<EstimateTaxResponse>(url, {
        address: shippingAddress,
        item: { nid: item.nid, oid: item.oid, qty: 1 },
    });

    if (response.status === 404) {
       throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        throw new Error("Unable to load release lists.");
    }
    return response.data;
}

export const CountriesApi =  async(token?: string): Promise<CountriesResponse> =>  {
    const url = `/api/settings/countries`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<CountriesResponse>(url, {});

    if (response.status === 404) {
       throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        throw new Error("Unable to load release lists.");
    }
    return response.data;
}

export const ProvincesApi =  async(country: number, token?: string): Promise<ProvincesResponse> =>  {
    const url = `/api/settings/provinces`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<ProvincesResponse>(url, {
        country: country,
    });

    if (response.status === 404) {
       throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        throw new Error("Unable to load release lists.");
    }
    return response.data;
}
export const CheckoutApi =  async( currentAccount:string, item:Object, release: Product, shippingAddress: Address, token?:string): Promise<CheckoutResponse> =>  {
    const url = `/api/order/checkout`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const product = Object.assign({},item, {qty :1});
    const response = await http.post<CheckoutResponse>(url, {
        walletId: currentAccount,
        item: product,
        release: release,
        address: shippingAddress
    });

    if (response.status === 404) {
       throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        throw new Error("Unable to load release lists.");
    }
    return response.data;
}

