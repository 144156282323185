import React, {useEffect, useState} from 'react';

import "./styles.scss";

import HomeFirstContainer from "./home-first-container";
import OurCollectionContainer from './our-collection-container'
import RoadMapContainer from "./road-map-container";
import FaqContainer from "./faq-container";
import OurTeamContainer from "./our-team-container";

type Props = {
    currentAccount?: string;
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,     
}

const Home: React.FC<Props> = ({currentAccount, setCurrentAccount, token, setToken}) => {

    const [error, setError] = useState(false);

    useEffect(() => {}, []);

    return (
        <>
            <HomeFirstContainer currentAccount={currentAccount}/>
            <OurCollectionContainer setCurrentAccount={setCurrentAccount} currentAccount={currentAccount} setToken={setToken} token={token}/>
            <RoadMapContainer/>
            <OurTeamContainer/>
            <FaqContainer />
        </>
    )
}

export default Home;