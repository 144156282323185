import ReleaseItem from './item';
import { Row } from 'react-bootstrap';

const Related = (props) => {
    if (props.items.length > 0) {
        const releases = props.items.map((release, index) => {
            return (
                <ReleaseItem key={release.id} item={release} country={props.country} />
            );
        });
        return (
            <>
                <h3>RELATED</h3>
                <Row>{releases}</Row>
            </>
        );
    } else {
        return <></>;
    }
}

export default Related;

