import { Link } from 'react-router-dom';
import ucfirst from 'ucfirst';
import { Col } from 'react-bootstrap';
import { httpCorrectUrl,  isNearRetail } from '../util/helper';


const ReleaseItem = (props) => {
    const item = props.item;
    const link = '/release/' + item.slug;
    const src = httpCorrectUrl(item.image);
    const title = ucfirst(item.title);
    const price = item.product_prices ? (item.product_prices[props.country] ? item.product_prices[props.country] : (item.product_prices['usa'] ? item.product_prices['usa'] : null)) : null;
    const retail_price = item.prices.retail_price>0 ? item.prices.retail_price : null;
    return (
        <Col xs={6} sm={4} md={4} lg={3} className="release-item" key={item.slug}>
            <div className='release-item-wrapper'>
                {retail_price && price && isNearRetail(item.id, retail_price, price)}
                <ItemPrice prices={item.prices} product_prices={item?.product_prices} country={props.country} />
                <div className="release-item-image">
                    <Link to={link}>
                        <img id={item.id} className='img-fluid' alt={title} src={src} />
                    </Link>
                    {/* {props.api=='near_retail' && <ItemDicount prices={item.prices} product_prices={item?.product_prices} country={props.country} />} */}
                </div>
                
                <div className="release-item-txt">
                    <Link to={link}>{title}</Link>
                </div>
            </div>
        </Col >
    );
}

const ItemPrice = (props) => {
    const user_price = props.product_prices ? (props.product_prices[props.country] ? props.product_prices[props.country] : (props.product_prices['usa'] ? props.product_prices['usa'] : null)) : null;
    if (user_price && user_price > 1) {
        const price = Math.round(user_price).toLocaleString();

        
        // <FormatCurrency value={price} />
        // const price = parseFloat(user_price);
        return (
            <div className="release-price-from">
                <span className='buy-from-price'>{'$' + price}</span>
            </div>
        )
    }
    return <></>;
}
const ItemDicount = (props) => {
    const user_price = props.product_prices ? (props.product_prices[props.country] ? props.product_prices[props.country] : (props.product_prices['usa'] ? props.product_prices['usa'] : null)) : null;
    let discount = 0;
    if (user_price && user_price > 1 && props.prices.hasOwnProperty('retail_price') && props.prices.retail_price > user_price) {
        discount = Math.round((props.prices.retail_price - user_price) / props.prices.retail_price * 100);
    }
    if (discount && discount > 1) {
        return (
            <div className="release-price-dicount">
                <span className='percent-off'>{discount.toLocaleString() + '% Off at Retail'}</span>
            </div>
        )
    }
    return <></>;
}


export default ReleaseItem;
