import React, {useState} from 'react';
import {Container, Nav, Navbar, Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Logo from "../../images/bears-club-white.png";
// import { Search } from 'react-bootstrap-icons';

import {clearCacheProvider, connectWallet, getCurrentAccount} from "../../network/ethereum";
// import Sidebar from "../release/sidebar";
import {checkAccountConnect} from "../../api/api-service";
import "./styles.scss";

type Props = {
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,    
}

const Component: React.FC<Props> = ({currentAccount, setCurrentAccount, token, setToken}) => {

    const [expanded, setExpanded] = useState(false);
    // const [sideBarOpen, setSideBarOpen] = useState(false);

    const connect = async () => {
        try {
            let provider = await connectWallet();
            if (provider.on) {
                provider.on("accountsChanged", async (accounts: any) => {
                    if (accounts.length === 0) {
                        clearCacheProvider();
                        setCurrentAccount(undefined);
                    }
                });
            }
            const account = await getCurrentAccount();
            const connectedAccount = await checkAccountConnect(account);
            setCurrentAccount(account);
            setToken(connectedAccount?.data?.token);
        } catch (err) {
            console.log(err);
        }
    }

    const disconnect = async() => {
        await clearCacheProvider();
        setCurrentAccount(undefined);
        setToken(undefined);
    }

    // const openSideBar = () =>{
    //     setSideBarOpen(true);
    //     return false;
    //   }
    
    // const closeSideBar = () =>{
    //     setSideBarOpen(false);
    //     return false;
    // }    

    return (
        <>
            <Navbar bg="primary" expand="lg" variant="dark" role="navigation" expanded={expanded}>
                <Container>
                    <Navbar.Brand as={NavLink} to="/" className=""></Navbar.Brand>
                    <Nav as={NavLink} to="/"><img  className="site-logo" src={Logo} alt="Bears Club" /></Nav>
                    <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(expanded ? false : true)} />
                    <Navbar.Collapse  id="navbar">
                        <Nav className='ms-auto  nav-justified flex-column flex-sm-row'>
                            {/* <li id='search-desktop'>
                                <NavLink  to={"#"} onClick={() => openSideBar()} key={'search-desctop-icon'}><Search /></NavLink>
                            </li>                            
                            <div id="search-mobile">
                                <Button variant="link" onClick={() => openSideBar()}> asas<i className="social-icon fa fa-search"></i></Button>
                            </div> */}

                            <Nav.Item>
                                <NavLink   onClick={() => setExpanded(false)} key={'shop'} className="btn btn-block btn-rounded btn-flat btn-bears" role="button" to={'/releases'}>{'Shop'}</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink   onClick={() => setExpanded(false)} key={'nearretail'} className="btn btn-block btn-rounded btn-flat btn-bears" role="button" to={'/near-retail'}>{'Near Retail'}</NavLink>
                            </Nav.Item>
                            {!currentAccount &&
                                <Nav.Item>
                                    <NavLink className="btn btn-block btn-rounded btn-flat btn-bears" 
                                        onClick={() => connect()} to={' '}  role="button">Connect To Wallet</NavLink>
                                </Nav.Item>
                            }
                            {currentAccount && token && 
                                <>
                                    <Nav.Item>
                                        <NavLink className="btn btn-block btn-rounded btn-flat btn-bears"
                                        to={'/orders'}  onClick={() => setExpanded(false)} role="button">My Orders</NavLink>
                                    </Nav.Item> 
                                    <Nav.Item>
                                        <NavLink to={" "} className="btn btn-block btn-rounded btn-flat btn-bears"
                                        onClick={() => disconnect()} role="button">Disconnect</NavLink>
                                    </Nav.Item>
                                </>
                            }
                        </Nav>
                        {/* <Sidebar isOpen={sideBarOpen} closeSideBar={() => closeSideBar()} /> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Component;