import axios from "axios";
import {BaseApiResponse, AXIOS_CONFIG} from "./api-constants";
import {Product, RelatedItems, Item, Size, Query} from "../models"

interface ReleaseListResponse extends BaseApiResponse {
    data?: {
        items:Array<Item>,
        page: number,
        perpage: number
        total: number,
    },
}

interface ReleaseViewResponse extends BaseApiResponse {
    data?: {
        release: Product,
        related: RelatedItems,
        sizes: Array<Size>
    },
}

interface SearchSuggestResponse extends BaseApiResponse {
    code: number,
    detail:string,
    data?: {
        results: Array<Query>,
    },
}



export const ReleaseListApi = async (api:string, page: number, order:string = 'popular', perpage: number, keyword:string ='', filter: object={}, token?:string): Promise<ReleaseListResponse> => {
    const url = `/api/release/search`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    
    const http = axios.create(requestConfig);
    const response = await http.post<ReleaseListResponse>(url, {
        page: page,
        api: api,
        perPage: perpage ? perpage : 20,
        keyword: keyword,
        sort: order,
        filter: filter
    });

    if (response.status === 404) {
        return { success:false, msg:"Invalid page request."}
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        return { success:false, msg:"Unable to load release lists."}
        // throw new Error("Unable to load release lists.");
    }
    return response.data;
}

export const ReleaseViewApi = async (slug: any, token?:string): Promise<ReleaseViewResponse> => {
    const url = `/api/release/view`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<ReleaseViewResponse>(url, {
        "slug": slug,
    });

    if (response.status === 404) {
        return { success:false, msg:"Invalid page request."}
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        return { success:false, msg:"Unable to load release lists."}
        // throw new Error("Unable to load release lists.");
    }
    return response.data;
}


export const SearchSuggestApi = async (value: string, token?:string): Promise<SearchSuggestResponse> => {
    const url = `searchSuggestion/suggest/search`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token), {baseURL:'https://kofsearchservice.com/api/v3/'});

    const params = new URLSearchParams([['query', value.trim()]]);

    const http = axios.create(requestConfig);
    const response = await http.get<SearchSuggestResponse>(url, {params});

    if (response.status === 404) {
        // return { success:false, msg:"Invalid page request."}
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        // return { success:false, msg:"Unable to load release lists."}
        // throw new Error("Unable to load release lists.");
    }
    return response.data;
}


// https://kofsearchservice.com/api/v3/searchSuggestion/suggest/search


/*
export const ReleaseSearchApi =  async (body, successCallback, errorCallback, token) => {

    body.perpage = 5;
    body.page = 1;
    body.exclude_fields = ["prices", "style_code"];
    if (body.hasOwnProperty("filter")) {
        body.filter.location = "any";
    } else {
        body.filter = {
            location: "any"
        }
    }    
    const url = `/api/release/search`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<ReleaseListResponse>(url, body);


    if (response.status === 404) {
        return { success:false, msg:"Invalid page request."}
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        return { success:false, msg:"Unable to load release lists."}
        // throw new Error("Unable to load release lists.");
    }
    const searchData = {
        data: response.data.items,
        total: response.data.total
    }
    successCallback(searchData);    
    return response.data;


    return axios(options).then((response) => {
        const json = response.data;
        if (json.success) {
            // const items = json.data.items.map((item) => {
            //     item.image = httpCorrectUrl(item.image);
            //     return item;
            // });
            const searchData = {
                data: json.data.items,
                total: json.data.total
            }
            successCallback(searchData);
        } else {
            errorCallback(json.msg);
        }
    }).catch((error) => {
        errorCallback(error);
    });
}
*/
