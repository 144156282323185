import {useEffect, useState} from "react";
import {Pagination} from "react-bootstrap";
// import {useSearchParams } from 'react-router-dom';

const  NFTPagination = (props) =>{
  const [pageArray, setPageArray] = useState<any>([]);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    var totalPages = parseInt(props.totalPages);
    var currentPage = parseInt(props.currentPage);
    let pageArr:any = [];
    if (totalPages > 1) {
      if (totalPages <= 9) {
        let i = 1;
        while (i <= totalPages) {
          pageArr.push(i);
          i++;
        }
      } else {
        if (currentPage <= 3){ 
          pageArr = [1, 2, 3, 4, "", totalPages-1, totalPages];
        }else if (totalPages - currentPage <= 2){
          pageArr = [
            1,
            "",
            totalPages - 3,
            totalPages - 2,
            totalPages - 1,
            totalPages
          ];
        }else{
          pageArr = [
            1,
            "",
            currentPage - 1,
            currentPage,
            currentPage + 1,
            "",
            totalPages
          ];
        }
      }
    }
    // setCurrentPage(parseInt(props.currentPage));
    setPageArray(pageArr);
  }, [props.currentPage, props.totalPages]);
  return (
        <Pagination style={{ justifyContent: "center" }}>
          {pageArray.map((ele, index) => {
            let toReturn:any = [];
            if (index === 0) {
              toReturn.push(
                <Pagination.First  
                key={"firstpage"}
                  onClick={
                    props.currentPage === 1
                      ? () => {}
                      : () => {
                          props.pageClicked(1);
                        }
                  }
                />
              );

              toReturn.push(
                <Pagination.Prev 
                  key={"prevpage"}
                  onClick={
                    props.currentPage === 1
                      ? () => {}
                      : () => {
                          props.pageClicked(props.currentPage - 1);
                        }
                  }
                />
              );
            }

            if (ele === "") toReturn.push(<Pagination.Ellipsis key={index}  />);
            else
              toReturn.push(
                <Pagination.Item 
                  key={index} 
                  disabled={props.currentPage === ele}  
                  active={props.currentPage === ele ? true : false}
                  onClick={
                    props.currentPage === ele
                      ? () => {}
                      : () => {
                          props.pageClicked(ele);
                        }
                  }
                >{ele}</Pagination.Item>
              );

            if (index === pageArray.length - 1) {
              toReturn.push(
                <Pagination.Next
                  key={"nextpage"}
                  onClick={
                    props.currentPage === ele
                      ? () => {}
                      : () => {
                          props.pageClicked(props.currentPage + 1);
                        }
                  }
                />
              );

              toReturn.push(
                <Pagination.Last 
                  key={"lastpage"} 
                  disabled={props.currentPage === ele}  
                  onClick={
                    props.currentPage === ele
                      ? () => {}
                      : () => {
                          props.pageClicked(ele);
                        }
                  }
                />
              );
            }
            return toReturn;
          })}
        </Pagination>
  );
}

export default NFTPagination;