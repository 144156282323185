import Web3 from 'web3';
import abi from '../contracts/abi.json';
import {AbiItem} from "web3-utils";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";
import WalletLink from "walletlink";
import Web3Modal from "web3modal";

const REACT_APP_API_URL: string | undefined = process.env.REACT_APP_API_URL;

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: "b903a94a637d4bd5ae7e015ce469dd3d",
        }
    },

    fortmatic: {
        package: Fortmatic,
        options: {
            key: "pk_live_EDDC485A766649DC"
        }
    },
    walletlink: {
        package: WalletLink, // Required
        options: {
            appName: "Bears Club By KicksOnFire",
            infuraId: "b903a94a637d4bd5ae7e015ce469dd3d",
            appLogoUrl: null,
            darkMode: true
        }
    }
};

const web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions,
    disableInjectedProvider: false,
});

const web3 = new Web3(new Web3.providers.HttpProvider(REACT_APP_API_URL!!));
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
// @ts-ignore
const contract = new web3.eth.Contract(abi as AbiItem[], contractAddress);
const PRICE = 0.3;

export const connectWallet = async (): Promise<any> => {
    web3Modal.clearCachedProvider();
    let provider = await web3Modal.connect();
    web3.setProvider(provider);
    return provider;
}

export const connectCacheWallet = async (): Promise<any> => {
    if (web3Modal.cachedProvider) {
        let provider = await web3Modal.connect();
        web3.setProvider(provider);
        return provider;
    }
    return null;
}

export const clearCacheProvider = () => {
    web3Modal.clearCachedProvider();
}

export const getCurrentAccount = async (): Promise<string> => {
    let accounts = await web3.eth.getAccounts();
    return accounts[0];
}

export const getBalance = async (address: string, tokenId: string) => {
    return await contract.methods.balanceOf(address, tokenId).call();
}

export const getMaxSupply = async (tokenId: string) => {
    return await contract.methods.getMaxSupply(tokenId).call();
}

export const getTotalSupply = async (tokenId: string) => {
    return await contract.methods.totalSupply(tokenId).call();
}

export const getTokenUri = async (tokenId: string) => {
    return await contract.methods.uri(web3.eth.abi.encodeParameter("uint256", tokenId)).call();
}

export const buyNtf = async (tokenIds: string[], amounts: number[]) => {
    let totalAmount = amounts.reduce((prev, curr) => prev + curr, 0);
    let value = (totalAmount * PRICE).toFixed(4);
    let currentAccount = await getCurrentAccount();
    return await contract.methods.mintKicksOnFireBears(tokenIds, amounts)
        .send({
            from: currentAccount,
            to: contractAddress,
            value: web3.utils.toHex(web3.utils.toWei(value.toString(), 'ether')),
            gasLimit: web3.utils.toHex(500000)
        });
}