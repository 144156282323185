import {useEffect, useState} from "react";
import tokens from "../tokens/token-list.json"
import {getTotalSupply, getMaxSupply} from "../network/ethereum";

type tokenMetadata = {
    tokenId: string,
    name: string,
    description: string,
    image: string,
    totalSupply: number,
    maxTotalSupply: number,
    balance: number,
}

export const useContract = (address?: string) => {
    const [loading, setLoading] = useState(false);
    const [tokenMetadataList, setTokenMetadataList] = useState<tokenMetadata[]>([]);

    const fetchTokenList = async (address?: string) => {
        setLoading(true);
        setTokenMetadataList([]);

        let tml: tokenMetadata[] = [];
        for (const tokenId of tokens) {
            let metadata = await fetchTokenMetadata(tokenId);
            //let totalSupply = await getTotalSupply(tokenId);
            //let maxTotalSupply = await getMaxSupply(tokenId);
            metadata.tokenId = tokenId;
            //metadata.maxTotalSupply = maxTotalSupply - totalSupply;
            tml.push(metadata);
        }
        setTokenMetadataList(tml);
        setLoading(false);
    }

    const fetchTokenMetadata = async (tokenId: string) => {
        const response = await fetch('https://bearsclubkof.mypinata.cloud/ipfs/QmfE6NMKCBcJMrcP5zqmCx99aLDD9qcd4J3H6ctwVt99c4/{id}.json'.replace('{id}', tokenId), {
            mode: "cors"
        });
        const json: tokenMetadata = await response.json();
        return json;
    }

    useEffect(() => {
        fetchTokenList(address);
    }, [address])

    return {tokenMetadataList, loading, setLoading, fetchTokenList};
}