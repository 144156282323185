import React, {useState} from "react";
import {Container, Form, InputGroup} from "react-bootstrap";
import { toast } from "react-toastify";

import './styles.scss';
import {generateCoupon} from "../../api/api-service";

type Props = {
}

const AccessCode: React.FC<Props> = () => {
    const [validated, setValidated] = useState(false);
    const [accessCode, setAccessCode] = useState<string| undefined>(undefined);

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        let token = event.target.token.value;
        let loadingId = toast.loading("Generating access code. Hang tight!");
        try {
            let response = await generateCoupon(token);
            if (!response.success) {
                toast.update(loadingId, { isLoading: false, render: response.message, type: "error" });
                return;
            }
            setAccessCode(response.body.code);
            toast.update(loadingId, { isLoading: false, render: "Access code generated",
                closeButton: true, autoClose: false, type: "success"  });
        } catch (e: any) {
            toast.update(loadingId, { isLoading: false, render: e.message, type: "error" });
        }
    };

    return <>
        <Container>
            <div className="access-code-generating-form mt-5">
                <h1 className="text-center mt-3">Access Code</h1>
                <h5 className="text-center mt-1 text-muted">Generate Access Code</h5>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Token</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control required name="token" type="text" placeholder="Enter the token for generate the access code" />
                            <Form.Control.Feedback type="invalid">
                                Token is required for generate the access code
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <input type="submit" value="Generate Code" className="btn btn-primary mt-2"/>
                </Form>
                {accessCode !== undefined && <div className="mt-5">
                    <p className="text-bold fs-4"><span className="text-uppercase">Generated Access Code </span><span className="badge bg-success fs-3">{accessCode}</span></p>
                </div>}
            </div>
        </Container>
    </>
}

export default AccessCode;