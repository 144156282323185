import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import { getSelectOption } from '../util/helper/index';
import {Query} from "../../models";
import {SearchSuggestApi} from "../../api/kof-api-service";
import MD5 from "crypto-js/md5"

const ReleaseHeader = (props) => {
    const [search, setSearch] = useState<string>(
        props.searchKeyword || ''
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [noResult, setNoResult] = useState<boolean>(true);
    const [toggleSuggest, setToggleSuggest] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<Array<Query>>([]);    

    useEffect(() => {
        setSearch(props.searchKeyword || '');
    },[props.searchKeyword]);

    useEffect(() => {
        const fetchAPI = async () => {
            if(!loading){
                setLoading(true);
                let response = await SearchSuggestApi(search.trim());
                if(response.detail ==='Success' && response?.data && response?.data?.results?.length>0){
                    setNoResult(false);
                    setSuggestions(response?.data?.results);
                }else{
                    setNoResult(true);
                    setSuggestions([]);
                }
                setLoading(false);
            }
        }    
        fetchAPI();
    },[search]);



    useEffect(() => {
        if(toggleSuggest){
            showSuggestion();
        }else{
            hideSuggestion();

        }
    },[toggleSuggest]);

    const toggleFilterSidebar=()=> {
        props.toggleFilterAction();
    }

    const handleSortByChange=(event) =>{
        const value = event.value;
        props.setSortby(value);
    }

    const showSuggestion = () => {
        document.getElementById('search-continer')?.classList.add('open-suggest');
    }

    const hideSuggestion = () => {
        document.getElementById('search-continer')?.classList.remove('open-suggest');
    }    
    
    const handleSearchChange=(event) =>{
        const value = event.target.value;
        setSearch(value);
        if(!toggleSuggest){
            setToggleSuggest(true);
        }
    }

    const toggleCurrentSelect = (event, isOver:boolean=false) => {
        if(isOver){
            event.target.classList.add('current-suggest');
        }else{
            event.target.classList.remove('current-suggest');
        }
    }

    const handleSearchKeyUp=(event) =>{
        const keyCode = event.keyCode || event.which;
        const current_selection = document.getElementsByClassName('current-suggest')[0];
        const suggest_items = document.getElementsByClassName('suggest-item');
        let index:number;
        if(!toggleSuggest){
            setToggleSuggest(true);
        }
        switch(keyCode){
            case 13:
                setLoading(false);
                props.updateSearchParams([{page : '1'},{ q:search}]);
                setToggleSuggest(false);
                break;
            //UP
            case 38:
                setLoading(true);
                if(current_selection){
                    index = parseInt(current_selection?.getAttribute('data-key') || '0');
                    suggest_items[index].classList.remove('current-suggest');
                    if(index > 0){
                        index -= 1; 
                    }else{
                        index = suggest_items.length-1
                    }
                }else{
                    index = suggest_items.length-1;
                }
                suggest_items[index].classList.add('current-suggest');
                setSearch(suggest_items[index]?.getAttribute('data-query') || '');
                break;
            //DOWN    
            case 40:
                setLoading(true);
                if(current_selection){
                    index = parseInt(current_selection?.getAttribute('data-key') || '0');
                    suggest_items[index].classList.remove('current-suggest');
                    if(index < suggest_items.length - 1){
                        index += 1; 
                        suggest_items[index].classList.add('current-suggest');
                    }else{
                        index = 0;
                    }
                }else{
                    index = 0;
                }
                suggest_items[index].classList.add('current-suggest');
                setSearch(suggest_items[index]?.getAttribute('data-query') || '');
                break;
        }
    }   
    
    const handleSuggestSelect = (event) =>{
        const value = event.target.getAttribute('data-query');
        setSearch(value);
        setToggleSuggest(false);
        props.updateSearchParams([{page : '1'},{ q: value}]);

    }

    const updateSuggestionDisplay = () =>{
        const result = !noResult ? suggestions.map((item, index) => {
            return (
                <div className="suggest-item" key={MD5(item.query).toString()} 
                    data-key={index} 
                    data-query={item.query} 
                    onMouseOver={(event)=>toggleCurrentSelect(event, true)} 
                    onMouseOut={(event)=>toggleCurrentSelect(event, false)}  
                    onClick={handleSuggestSelect.bind(this)} 
                    onTouchEnd={handleSuggestSelect.bind(this)} 
                >{item.query}</div>
            );
        }) : <div  className="no-result">No Result</div>;
        return result;             
    }

    const options = props.listSortby;
    const selected_sort = getSelectOption(props.sortby, props.listSortby);
    
    return (
        <Row id="release-header">
            <Col xs={1} sm={1} md={3} lg={3} id='showFilterContainer'>
                <div className="show-filter" onClick={() => toggleFilterSidebar()} >
                    {props.isFilterShow ? "Hide Filters" : "Show Filters"}<span className="icon"><i className="fa fa-angle-right"></i></span>
                </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={6} id="search-continer" 
                // onBlur={()=>{setToggleSuggest(false)}} 
                onFocus={()=>{setToggleSuggest(true)}} >
                <Form.Control  
                    type="text" 
                    value={search}
                    placeholder="I'm looking for..." 
                    name="search" 
                    id="search"  
                    autoCorrect='off' autoComplete='off' 
                    onKeyUp={(event)=>handleSearchKeyUp(event)}  
                    onChange={handleSearchChange.bind(this)} 
                    onBlur={()=>{setToggleSuggest(false)}} 
                    />
                    <div id="tranding-data" className="tranding-data">
                        {updateSuggestionDisplay()}
                    </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={3} id='sortByContainer'>
                <FormGroup controlId="sltSortBy" className="row">
                    <Col xs={12}>
                        <Select
                            id='sltSortBy'
                            name="sltSortBy"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isClearable={false}
                            defaultValue={selected_sort}
                            value={selected_sort} 
                            isSearchable={false}
                            onChange={handleSortByChange.bind(this)}
                            options={options}
                        />
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    )
}
export default ReleaseHeader;
