const API_URL: string = process.env.REACT_APP_BACKEND_API_URL!;

export const API_HEADERS = (token?:string) => {
    let headers = {
        'Content-Type': 'application/json'
    };
    if (token) {
        headers = Object.assign({}, headers, {'Authorization':`Bearer ${token}`});
    }
    return headers;
}


export const AXIOS_CONFIG1 = {
    withCredentials: false,
    baseURL: API_URL,
    headers: API_HEADERS(),
    responseType: 'text' as 'json',
}

export const AXIOS_CONFIG = (token?:string) => {
    return {    
        withCredentials: false,
        baseURL: API_URL,
        headers: API_HEADERS(token),
        responseType: 'text' as 'json',
    }
}


export interface BaseApiResponse {
    success: boolean;
    error?: string;
    msg?: string;
}