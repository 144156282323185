import { usePlacesWidget } from "react-google-autocomplete";
import {Form} from 'react-bootstrap';

const MY_API_KEY = "AIzaSyD4bKGpM9kJEP_dLwcAQxW4oBOj3qfLbXw";

const Googleplaces = (props, onPlaceSelected) => {    
    const { ref } = usePlacesWidget({
      apiKey:MY_API_KEY,
      onPlaceSelected: onPlaceSelected,
      libraries: ["places", "geometry"],   
      options:{
        types: ["geocode"],
      }
    });
    return <Form.Control ref={ref} {...props} />;
}

export default Googleplaces;