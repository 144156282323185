import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button, OverlayTrigger, Tooltip, Row, Col, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import { EstimateDeliveryApi } from "../../api/kixify-api-service";
import {checkAccountConnect, checkTokenAPI} from "../../api/api-service";
import { isNearRetail} from '../util/helper/index';
import {Product, Size} from "../../models";
import {BallTriangle} from 'react-loader-spinner';

import {
  clearCacheProvider,
  connectWallet,
  getCurrentAccount,
} from "../../network/ethereum";
import Checkout from "./checkout";

type Props = {
  currentAccount?: string,
  setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
  token?: string,
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>,    
  release: Product,
  release_sizes: Array<Size>
}

const Component: React.FC<Props> = ({currentAccount, setCurrentAccount, token, setToken, release, release_sizes}) => {  
  const [error, setError] = useState(false);
  const [estimated_delivery, setEstimatedDelivery] = useState<string>("");
  const [item, setItem] = useState<Size>({});
  const [showCheckout, setShowCheckout] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchMyAPI = async () => {
      if (item?.nid) {
        let response = await EstimateDeliveryApi(item?.nid, {}, token);
        if (response.data.estimated_delivery.length > 0) {
          setEstimatedDelivery(response.data.estimated_delivery);
        } else {
          setEstimatedDelivery("");
        }
      } else {
        setEstimatedDelivery("");
      }
    }
    fetchMyAPI();
  }, [item]);

  const handleChange = (currentItem: Size) => {
    let selected_item:Size = {};
    if(currentItem?.google_id){    
      if (item?.google_id) {
        if (item?.google_id === currentItem?.google_id) {
          document.getElementById(currentItem?.google_id)?.classList.remove('selected');
        } else {
          document.getElementById(item?.google_id)?.classList.remove('selected');
          document.getElementById(currentItem?.google_id)?.classList.add('selected');
          selected_item = Object.assign({},currentItem);
        }
      } else {
        document.getElementById(currentItem?.google_id)?.classList.add('selected');
        selected_item = Object.assign({}, currentItem);
      }
    }
    if(selected_item?.hasOwnProperty('google_id')){
      setItem(selected_item);
    }

  };

  const onBuyNowClick = async () => {
    setError(false);
    if (!currentAccount) {
      // toast.info("Please connect your wallet.");
      connect();
      return;
    }
    if (item?.hasOwnProperty('nid')) {
      setLoading(true);
      
      let currentItem = Object.assign({}, item, {
        release: {
          id: release.id,
          name: release.title,
          thumb_image: release.thumb_image,
          slug: release.uri,
          style_code: release.style_code,
          style: release.style,
          brand_name: release.type[0] ? release.type[0].name : ''
        }
        
      });
      let response = await checkTokenAPI(currentAccount, token);
      setLoading(false);
      if(response.success && response.data && response.data.hasTokens && response.data.allowOrder){
        setShowCheckout(true);
      }else if(response.success && response.data && response.data.hasTokens && !response.data.allowOrder){
        toast.info(response.message?response.message :"You already made 3 orders last 30 days.", {
          position: "bottom-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }else{
        toast.info("You must first  buy BEARS CLUB NFT to access this resource.", {
          position: "bottom-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    return false;
};

const connect = async () => {
  try {
      let provider = await connectWallet();
      if (provider.on) {
          provider.on("accountsChanged", async (accounts: any) => {
              if (accounts.length === 0) {
                  clearCacheProvider();
                  setCurrentAccount(undefined);
              }
          });
      }
      const account = await getCurrentAccount();
      const connectedAccount = await checkAccountConnect(account);
      setCurrentAccount(account);
      setToken(connectedAccount?.data?.token);
  } catch (err) {
      console.log(err);
  }    
}

const handleCheckoutClose= async () => {
  setShowCheckout(false);
}

const decorateSizes = (sizes: Array<Size> = [], retail_price:number=0, id:number = 0) => {
  return sizes.map((sizeItem) => {
    const product_price_convert = String(sizeItem?.product_price).replace(/,/, "");
    const size_price_convert = String(sizeItem?.size_price).replace(/,/, "");
    const price = Math.round(parseFloat(product_price_convert) + parseFloat(size_price_convert));
    return <Col xs={3} sm={2} md={2} className="clear-paddings" onClick={(e) => handleChange(sizeItem)} id={sizeItem.google_id} key={sizeItem.google_id}>
      <div className="size-item">
        {retail_price>0 && price && isNearRetail(id, retail_price, price)}
        <div className="size-value">{sizeItem.size}</div>
        <div className="price-value">{'$' + price.toLocaleString()}</div> 
        {/* <FormatCurrency value={price} /> */}
      </div>
    </Col>
  });
}

  if (!(release_sizes.length > 0)) {
    return (
      <div className="out-of-stock">Not in Stock</div>
    );
  } else {
    const retail_price = release.price
    let sizes = [];
    release_sizes.map((size:Size, index:number) => {
      switch (size?.gender?.toLowerCase()) {
        case 'men':
          if (!sizes.hasOwnProperty('men')) {
            sizes['men'] = [];
          }
          sizes['men'].push(size);
          break;
        case 'women':
          if (!sizes.hasOwnProperty('women')) {
            sizes['women'] = [];
          }
          sizes['women'].push(size);
          break;
        case 'kids':
        case 'youth':
          if (!sizes.hasOwnProperty('kids')) {
            sizes['kids'] = [];
          }
          sizes['kids'].push(size);
          break;
      }
    });
    let men_sizes:Array<React.ReactElement> = [];
    let active = 0;
    if (sizes.hasOwnProperty('men') && sizes['men'].length > 0) {
      active = 1;
      men_sizes = decorateSizes(sizes['men'], retail_price, item.oid);
    }
    let women_sizes:Array<React.ReactElement> = [];
    if (sizes.hasOwnProperty('women') && sizes['women'].length > 0) {
      if (!active) {
        active = 2;
      }
      women_sizes = decorateSizes(sizes['women'], retail_price, item.oid);
    }
    let kids_sizes:Array<React.ReactElement> = [];
    if (sizes.hasOwnProperty('kids') && sizes['kids'].length > 0) {
      if (!active) {
        active = 3;
      }
      kids_sizes = decorateSizes(sizes['kids'], retail_price, item.oid);
    }
    const estimateDelivery = (
      estimated_delivery !== '' ? "Estimate Delivery: " + estimated_delivery : ''
    );
    const badge_image = '/default/shield-outline.png';
    const tooltip = (
      <Tooltip placement="top" className="in" id="tooltip-top">We offer our Authenticity Guarantee on all purchases.</Tooltip>
    );
    return (
      <>
        <Row id="buy-now-wrapper" >
          <div id="buy-now-from">
            <Col lg={12} className="clearfix">
              <div id="authentic-badge" data-toggle="tooltip" data-placement="top" title="" data-original-title="">
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <span><img className='' alt="100% Authentic Guarantee" src={badge_image} height="24px" title="100% Authentic Guarantee" /> 100% Authentic Guarantee</span>
                </OverlayTrigger>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} className="clearfix">
              <div className="d-grid gap-2">
              { loading && <Col md={12} className="text-center"><BallTriangle  wrapperClass="d-inline-flex text-center" color="#eb6864" height={80} width={80}/></Col> }
               {!loading && <Button variant="primary" type="button" className="btn-bears"  onClick={(e) => onBuyNowClick()}>
                  {'Buy Now'}</Button>}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} id="estimate_delivery">
              {estimateDelivery}
            </Col>
            <Col lg={12} className="clearfix release-sizes" >
              <Tabs variant='tabs' defaultActiveKey={active} id="release-size">
                {men_sizes?.length > 0 ? (
                  <Tab eventKey={1} title="Men's" ><Row className="clear-margins">{men_sizes}</Row></Tab>
                ) : ''}
                {women_sizes?.length > 0 ? (
                  <Tab eventKey={2} title="Women's"><Row className="clear-margins">{women_sizes}</Row></Tab>
                ) : ''}
                {kids_sizes?.length > 0 ? (
                  <Tab eventKey={3} title="Kid's"><Row className="clear-margins">{kids_sizes}</Row></Tab>
                ) : ''}
              </Tabs>
            </Col>
          </div>
        </Row>
        {token && currentAccount &&<Modal id="checkout-wrapper" show={showCheckout} onHide={handleCheckoutClose} size={'lg'} fullscreen={'xl-down'}>
          <Checkout 
            currentAccount={currentAccount} 
            setCurrentAccount={setCurrentAccount} 
            token={token} 
            setToken={setToken}             
            release={release} 
            item={item} 
             />
        </Modal>}      
      </>
    );
  }
}
export default Component;
