import React, {useState, useEffect} from 'react';
import {useSearchParams } from 'react-router-dom';
import {Col, Container, Row, Button} from "react-bootstrap";

import {BallTriangle} from 'react-loader-spinner';
import {ReleaseListApi} from "../../api/kof-api-service";
import ReleaseItem from "./item";
import ReleaseHeader from "./header";
import Filters from "./filters";

import NFTPagination from "../util/helper/pagination";
import {Item, Filter, Sort} from "../../models";

import "./styles.scss"

type Props = {
    api: string,
    setApi: React.Dispatch<React.SetStateAction<string | undefined>>
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,     
}

const Component: React.FC<Props> = ({ api, setApi, currentAccount, setCurrentAccount, token, setToken}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    
    const [page, setPage] = useState<number>(
        parseInt(searchParams.get('page') || '1')
    );

    const [searchKeyword, setSearchKeyword] = useState<string>(
        searchParams.get("q") || '' 
    );

    const [filters, setFilters] = useState<Filter>({});
    const [perPage, setPerPage] = useState<number>(24);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [order, setOrder] = useState(
        searchParams.get("order") || 'popular' 
    );

    const [releases, setReleases] = useState<Item[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [noresult, setNoresult] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    useEffect(() => {
        let currentPage = searchParams.get('page') || '1';
        if(page !== parseInt(currentPage)){
            setPage(parseInt(currentPage));
        }
        let currentOrder = searchParams.get('order') || 'popular';
        if(order!== currentOrder){
            setOrder(currentOrder);
        } 
        let keyword = searchParams.get('q') || '';        
        if(keyword !== searchKeyword){
            setSearchKeyword(keyword);
            updateSearchParams([{page : '1'},{ q:keyword}]); 
        }

        let tmpFilters:Filter = Object.assign({}, filters) ;
        let brand = searchParams.get('brand') || '';
        let brands = brand && brand.length>0? brand.trim().toLowerCase().split(',') : [];
        if( brands !== filters.brands){
            tmpFilters = Object.assign(tmpFilters, {brands: brands});
        }

        let size = searchParams.get('size') || '';
        let sizesString = size && size.length>0? size.trim().toLowerCase().split(',') : [];
        let sizes = sizesString.map(value=>{
            return parseFloat(value);
        });
        if( sizes !== filters.sizes){
            tmpFilters = Object.assign(tmpFilters, {sizes: sizes});
        }

        let gender = searchParams.get('gender') || '';
        let genders = gender && gender.length>0? gender.trim().toLowerCase().split(',') : [];
        if( genders !== filters.genders){
            tmpFilters = Object.assign(tmpFilters, {genders: genders});
        }

        let shipping = searchParams.get('shipping') || '';
        if(shipping !== filters.shipping){
            tmpFilters = Object.assign(tmpFilters, {shipping: shipping});
        }
        if(filters !== tmpFilters ){
            setFilters(tmpFilters);     
        }
    },[searchParams]);

    useEffect(() => {
        const fetchAPI = async () => {
            if(!loading){
                setLoading(true);
                let response = await ReleaseListApi(api, page, order, perPage, searchKeyword, filters, token);
                if(response.success && response?.data && response?.data?.items.length>0){
                    setNoresult(false);
                    setReleases(response.data.items);
                    setTotalPages(Math.ceil(response.data.total / perPage));
                }else{
                    setNoresult(true);
                    setReleases([]);
                    setTotalPages(1);
                }
                setLoading(false);
            }
        }
        fetchAPI();
    },[api, page, order, searchKeyword, filters]);

    const decorateItems = () => {
        const result = releases.length > 0 ? releases.map((release, index) => {
            return (
                <ReleaseItem item={release} api={api} key={index} country={'us'} />
            );
        }) : noresult ? (
            <Col lg={12} className="noresult">No result found.</Col>
        ) : '';    
        return result; 
    }

    const pagination = ()=>{
        if(totalPages > 1){ 
            
            return (
                <Col lg={12} id="pagination-wrapper">
                    <NFTPagination
                        totalPages={totalPages}
                        currentPage={page}
                        pageClicked={(ele:string) => {
                            updateSearchParams([{page : ele}]);
                        }} />
                </Col>
        )}
        return <></>;
    }

    const updateSearchParams=(params:Array<Object>=[{}])=>{
        const search = {}
        for (let entry of searchParams.entries()) {
            search[entry[0]] = entry[1];
        }
        let tmpSearchParams = {...search};
        for (let entry of params) {
            let key = Object.keys(entry)[0];
            let value = Object.values(entry)[0];
            switch(key){
                case 'page':
                    if(parseInt(value) ===1){
                        setPage(1);
                        delete tmpSearchParams['page'];
                    }else{
                        tmpSearchParams = {...tmpSearchParams,[key]:value};
                    }
                    break;
                case 'order':
                    if(value ==='popular'){
                        setOrder(value);
                        delete tmpSearchParams['order'];
                    }else{
                        tmpSearchParams = {...tmpSearchParams,[key]:value};    
                    }
                    break;    
                default:
                    if(value.trim() === ''){
                        delete tmpSearchParams[key];
                    }else{
                        tmpSearchParams = {...tmpSearchParams,[key]:value};
                    }
                    break;
            }
        }
        setSearchParams(tmpSearchParams);
    }

    const toggleFilter=()=>{
            if (showFilters) {
                setShowFilters(false);
                document.getElementsByTagName('body')[0].classList.remove('filter-open');        
            } else {
                setShowFilters(true);
                document.getElementsByTagName('body')[0].classList.add('filter-open');
            }
    }

    const setSortby = (value:string)=> {
        updateSearchParams([{page : '1'}, {order: value}]);
    }

    const setBrands=(value:Array<string> = []) =>{
        updateSearchParams([{page : '1'}, {brand: value.join(',')}]);
    }

    const setGenders=(value:Array<string> = []) =>{
        updateSearchParams([{page : '1'}, {gender: value.join(',')}]);
    }

    const setSizes=(value:Array<string> = []) =>{
        updateSearchParams([{page : '1'}, {size: value.join(',')}]);
    }

    const setShipping=(value:string = '') =>{
        updateSearchParams([{page : '1'}, {shipping: value}]);          
    }
  
    const clearFilter=()=> {
        updateSearchParams([{page : '1'},{brand:''},{size:''},{gender:''},{shipping:''}]);
    }

    const generateListSortBy = (key:string, api:string) =>{
        let sortItems:Array<Sort> = [];
        switch (api) {
            case 'under_retail':
                sortItems = [
                    {value:'popular', label:'Popular'},
                    {value:'discount-desc', label:'Highest % Off '},
                    { value: 'price-asc', label: 'Price (Low-High)' },
                    { value: 'price-desc', label: 'Price (High-Low)' }
                ];
                break;
            case 'near_retail':
                sortItems = [
                    {value:'popular', label:'Popular'},
                    {value:'discount-desc', label:'Highest % Off '},
                    { value: 'price-asc', label: 'Price (Low-High)' },
                    { value: 'price-desc', label: 'Price (High-Low)' }
                ];
                break;                
            default:
                if (key === "") {
                    sortItems = [
                        { value: 'popular', label: 'Popular' },
                        // { value: 'past', label: 'Past' },
                        // { value: 'upcoming', label: 'Upcoming' },
                        { value: 'price-asc', label: 'Price (Low-High)' },
                        { value: 'price-desc', label: 'Price (High-Low)' },
                    ];
                } else {
                    sortItems = [
                        { value: 'relevant', label: 'Relevant' },
                        { value: 'popular', label: 'Popular' },
                        // { value: 'past', label: 'Past' },
                        // { value: 'upcoming', label: 'Upcoming' },
                        { value: 'price-asc', label: 'Price (Low-High)' },
                        { value: 'price-desc', label: 'Price (High-Low)' },
                    ];
                }
                break;
        }
        return sortItems;
    }
    return (
        <div  className="mainpage-wrapper-container pt-5 pb-5 release-collection-section w-100">
            <Container>
                <Row>
                    <Col lg={12}>
                        <ReleaseHeader 
                            toggleFilterAction={() => toggleFilter()}  
                            searchKeyword={searchKeyword} 
                            setSearchKeyword={(value:string) => setSearchKeyword(value)} 
                            updateSearchParams ={(params:Array<Object>=[{}]) => updateSearchParams(params)} 
                            setSortby={(value:string) => setSortby(value)} sortby={order} 
                            listSortby={generateListSortBy(searchKeyword, api)} 
                            isFilterShow={showFilters}
                            api={api}  />
                    </Col>
                </Row>
                <Row id="row-container" className={showFilters ? "open-filters" : ""}>
                    {<Col id="column-filter" lg={3}>
                        <Filters
                            toggleSidebar={() => toggleFilter()}
                            setBrands={(value) => setBrands(value)}
                            setGenders={(value) => setGenders(value)}
                            setSizes={(value) => setSizes(value)}
                            setShipping={(value) => setShipping(value)}
                            clearFilter={() => clearFilter()}
                            brands={filters?.brands}
                            genders={filters?.genders}
                            sizes={filters?.sizes}
                            shipping={filters.shipping}
                            api={api} />
                    </Col>}                    
                    {loading &&
                        <Col id="release-loader" md={12} className="text-center">
                            <BallTriangle wrapperClass="d-inline-flex" color="#eb6864" height={80} width={80}/>
                        </Col>
                    }
                    {!loading && <Col id="release-filter" lg={12} className="justify-content-center">
                        <Row id='releases-container'>
                            {decorateItems()}
                            {pagination()}
                        </Row>
                    </Col>}
                </Row>
            </Container>     
            <div className="d-grid btn-filter-wrapper-float gap-2">
                <Button className="btn btn-bears-dark" style={{ display: showFilters ? "none" : "" }} onClick={() => toggleFilter()}>
                    Filter
                </Button>
            </div>
        </div>
    )
}


export default Component;