import axios from "axios";
import {BaseApiResponse, AXIOS_CONFIG} from "./api-constants";

import {Item, Order, Message} from "../models/order";

interface OrderListResponse extends BaseApiResponse {
    data?: {
        items?:Array<Item>,
        page?: number,
        perpage?: number
        total?: number,
    },
}

interface OrderViewResponse extends BaseApiResponse {
    data?: Order
}
interface MessagesResponse extends BaseApiResponse {
    data?: {
        items?:Array<Message>,
        page?: number,
        perpage?: number
        total?: number,
    },
}




export const OrderListApi = async (page: number, perpage: number, filter?:Object, token?: string): Promise<OrderListResponse> => {
    if(token === undefined){
        return { success:false, data:{}, msg:"You don't have access to this resource. "};//new Error("You don't have access to this resource. ");
    }

    const url = `/api/order/list`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));

    const http = axios.create(requestConfig);
    const response = await http.post<OrderListResponse>(url, {
        'page': page,
        'perPage':perpage ? perpage : 20,
    });

    if (response.status === 404) {
        return { success:false,  msg:"Invalid page request."};
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        return { success:false, msg:"Unable to load release lists."};
        // throw new Error("Unable to load release lists.");
    }
    return response.data;
}

export const OrderViewApi = async (orderId: any, token?: string): Promise<OrderViewResponse> => {
    if(token === undefined){
        return { success:false, msg:"You don't have access to this resource. "}
        // throw new Error("You don't have access to this resource. ");
    }
    const url = `/api/order/view`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<OrderViewResponse>(url, {
        "orderId": orderId,
    });

    if (response.status === 404) {
        return { success:false, msg:"Invalid page request."}
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        return { success:false, msg:"Unable to load release lists."}
        // throw new Error("Unable to load release lists.");
    }
    return response.data;
}

export const OrderMessagesApi = async (orderId: number, token?: string): Promise<MessagesResponse> => {
    if(token === undefined){
        return { success:false, data:{}, msg:"You don't have access to this resource. "};
        // throw new Error("You don't have access to this resource. ");
    }

    const url = `/api/order/messages`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<MessagesResponse>(url, {
        "orderId": orderId,
    });

    if (response.status === 404) {
        return { success:false, msg:"Invalid page request."}
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        return { success:false, msg:"Unable to load release lists."}
        // throw new Error("Unable to load release lists.");
    }
    return response.data;
}

export const OrderMessageSendApi = async (orderId: number, message?:string, subject:string = '', token?: string): Promise<MessagesResponse> => {
    if(token === undefined){
        return { success:false, data:{}, msg:"You don't have access to this resource. "};
        // throw new Error("You don't have access to this resource. ");
    }

    const url = `/api/order/messageSend`;
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    const response = await http.post<MessagesResponse>(url, {
        orderId: orderId,
        subject: subject,
        message: message        
    });

    if (response.status === 404) {
        return { success:false, msg:"Invalid page request."}
    //    throw new Error("Invalid page request.");
    }
    if (response.status !== 200) {
        return { success:false, msg:"Unable to load release lists."}
        // throw new Error("Unable to load release lists.");
    }
    return response.data;
}

/*
export const messages = (orderId, influencer, token) => {
    return (dispatch) => {
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/order/messages',
            data: { orderId: orderId }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch_json = {
                    type: 'ORDER_MESSAGES_LIST_SUCCESS',
                    messages: json.data,
                };
            } else {
                dispatch_json = {
                    type: 'ORDER_MESSAGES_LIST_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}

export const messageSend = (orderId, subject, message, influencer, token) => {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_ALERTS'
        });
        dispatch({
            type: 'APP_LOADER_LOADING',
        });
        const options = Object.assign({}, AXIOS_CONFIG, {
            headers: API_HEADERS(influencer, token),
            url: '/order/messageSend',
            data: {
                orderId: parseInt(orderId),
                subject: subject,
                message: message
            }
        });
        return axios(options).then((response) => {
            let dispatch_json = {};
            const json = response.data;
            if (json.success) {
                dispatch(messages(orderId, influencer, token));
                dispatch_json = {
                    type: 'ORDER_MESSAGES_SEND_SUCCESS',
                    messages: json.data,
                    alerts: [{ msg: json.msg }]
                };
            } else {
                dispatch_json = {
                    type: 'ORDER_MESSAGES_SEND_FAILURE',
                    alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
                };
            }
            return dispatch(dispatch_json);
        }).catch((error) => {
            dispatch({
                type: 'NETWORK_ERROR',
            });
        });
    };
}*/