import React from 'react';
import {Row} from "react-bootstrap";
import {motion} from "framer-motion";
import bear01image from "../../images/bears/01.png";
import bear05image from "../../images/bears/05.png";
import kofLogo from "../../images/kof-logo.png";
import discord from "../../images/social-media-icons/discord.svg";
import instagram from "../../images/social-media-icons/instagram.svg";
import twitter from "../../images/social-media-icons/twitter.svg";

type Props = {
    currentAccount?: string;
}

const Component: React.FC<Props> = ({ currentAccount }) => {

    return (
        <div className="home-first-container min-vh-100 w-100 overflow-hidden">
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            <Row className="min-vh-100">
                <div className="jumbotron d-flex align-items-center">
                    <div className="container">
                        <div className="ntf-home-gallery">
                            <motion.img
                                initial={{x: -100, scale: 2, rotate: -10}}
                                animate={{x: 0, scale: 1, rotate: 0}}
                                transition={{duration: 1.5}}
                                className="image-01" src={bear01image}/>
                            <motion.img
                                initial={{y: -100, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{duration: 1, delay: 0.5}}
                                className="image-02" src={bear05image}/>
                        </div>
                        <motion.h1
                            initial={{x: 300, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            exit={{x: -300, opacity: 0}}
                            transition={{duration: 1, delay: 0.5}}
                            className="text-center font-monospace">
                            <span className="highlight-word-white">Bears Club</span>
                            <span className="highlight-by-word">&nbsp;By&nbsp;</span>
                            <span className="highlight-word">
                                <img className="kof-logo" src={kofLogo} alt="KicksOnFire Logo" />
                            </span>
                        </motion.h1>
                        <motion.h5
                            initial={{x: -300, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            exit={{x: 300, opacity: 0}}
                            transition={{duration: 0.5, delay: 1.0}}
                            className="text-center">
                            A collection of 5,555 Bears will be joining the NFT space on Opensea.
                        </motion.h5>
                    </div>
                </div>
            </Row>

            <div className="wallet-approved-message">
                <motion.h5
                    initial={{x: 0, opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    transition={{duration: 1, delay: 0.5}}
                    className="text-center">MINT IS CLOSED. <br/> FOR EARLY ACCESS, JOIN OUR DISCORD.</motion.h5>
                <motion.div
                    initial={{x: 0, opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    transition={{duration: 1, delay: 0.5}}
                    className="social-media-section text-center mt-1">
                    <a href="http://discord.gg/dVRpFfMfrF">
                        <img className="social-media-icon" src={discord}  alt="Discord"/>
                    </a>
                    <a href="https://www.instagram.com/kicksonfire">
                        <img className="social-media-icon" src={instagram}  alt="Instagram"/>
                    </a>
                    <a href="https://twitter.com/kicksonfire">
                        <img className="social-media-icon" src={twitter}  alt="Twitter"/>
                    </a>
                </motion.div>
            </div>
        </div>
    )
}

export default Component;