import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import {motion} from "framer-motion";
import {BallTriangle} from 'react-loader-spinner';
import {  Link } from 'react-router-dom';

type Props = {
  currentAccount?: string,
  setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
  token?: string,
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>,     
}

const Component: React.FC<Props> = ({currentAccount, setCurrentAccount, token, setToken}) => {

  const { orderId } = useParams();
  const [loading, setLoaded] = useState<boolean>(false);
  /*
  constructor(props) {
    super(props);
    const metatags = page_metas.checkout_complete;
    this.state = {
      pageTitle: metatags.title,
      orderId: this.props.match.params['orderId'],
      order: {},
      is_loaded: 0
    };
    this.props.setMetatags(metatags);
  }

  componentWillUnmount() {
    this.props.clearOrder();
  }
  componentDidMount() {
    this.props.clearOrder();
    this.props.viewOrder(this.state.orderId, this.props.influencer, this.props.token, true);
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.loading) {
      if (props.order && props.order.orderId && !state.is_loaded) {
        props.checkout_complate(state.orderId, props.influencer, props.token);
        const order = props.order;
        const item = order.orderProductItems[0];
        const sz = item.size.replace('.', '_');
        const style_code = item.styleCode
          .replace('-', '')
          .replace(' ', '')
          .toUpperCase()
          .trim();
        const id = style_code + '-' + sz;// + '-' + g;
        const price = parseFloat(order.orderTotal);
        const pixel_json = {
          content_name: item.name,
          content_category: item.brand[0],
          currency: 'USD',
          content_type: 'product',
          contents: [{
            id: id,
            quantity: 1,
            item_price: price
          }],
          content_ids: [id],
          value: price,
          num_items: 1
        }
        ReactPixel.track('Purchase', pixel_json);
        const ga_adwords = props.influencer.analytics.adwords ? props.influencer.analytics.adwords : '';
        if (ga_adwords) {
          const ga_adwords_conversion = props.influencer.analytics.adwordsconversion ? props.influencer.analytics.adwordsconversion : '66DaCLH_5n4Q7_qMywM';
          window.gtag('config', ga_adwords);
          window.gtag('event', 'conversion', {
            'send_to': ga_adwords + '/' + ga_adwords_conversion,
            'value': order.orderTotal,
            'currency': 'USD',
            'transaction_id': order.orderId
          });
          if (order.seller_estimate) {
            var google_opt = document.createElement("script");
            google_opt.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn';
            google_opt.async = true;
            google_opt.defer = true;
            document.getElementsByTagName('body')[0].appendChild(google_opt);
            const surveyoptin = {
              "merchant_id": 120810012,
              "order_id": JSON.stringify(order.orderId),
              "email": order.shippingAddress.emailAddress,
              "delivery_country": order.shippingAddress.countryIsoCode2,
              "estimated_delivery_date": order.seller_estimate,
            };
            window.renderOptIn = function () {
              window.gapi.load("surveyoptin", function () {
                window.gapi.surveyoptin.render(surveyoptin);
              });
            };
          }
        }
        window.gtag('event', 'purchase', {
          'event_label': 'Order #' + order.orderId,
          "transaction_id": order.orderId,
          "affiliation": props.influencer.subdomain,
          "value": order.orderTotal,
          "currency": "USD",
          "tax": order.orderTax,
          "shipping": order.orderShipping,
          "items": [
            {
              "id": id,
              "name": item.name,
              "sku": item.styleCode,
              "brand": item.brand[0],
              "category": item.brand[0],
              "list_position": 1,
              "quantity": 1,
              "price": price
            }
          ]
        });
        window.uetq = window.uetq || [];
        window.uetq.push('event', '', { 'revenue_value': order.orderTotal, 'currency': 'USD' });
        return {
          order: order,
          is_loaded: 1
        }
      }
      //  else {
      //   props.history.push('/orders');
      // }
    }
    return null;
  }*/
  if(orderId && !loading){
    const order_link = '/order/' + orderId;
    const show_order = !loading ? <div><h2>Thanks for your order!</h2><p>Your Order  Number: <Link to={order_link}><b><u>{orderId}</u></b></Link></p><p>Your order has been placed. You'll receive a confirmation email shortly.</p></div> : '';
    return (
          <div className="mainpage-wrapper-container pt-5 pb-5 order-view-section w-100 overflow-hidden">
            {loading &&
                <Row className="text-center">
                    <BallTriangle wrapperClass="d-inline-flex" color="#eb6864" height={80} width={80}/>
                </Row>
            }
            {!loading && <Container>
                <Row className='pb-5'>
                    <motion.h1
                    initial={{ y: -80, scale: 0.5 }}
                    whileInView={{ y: 0, scale: 1 }}
                    transition={{
                        type: 'circIn',
                        ease: 'anticipate',
                        duration: 1
                    }}
                    className="text-left">Complete</motion.h1>
                </Row>
                <Row id="checkout-wrapper" className="justify-content-center">
                    <Col xs={12} sm={12} md={12}>
                      {show_order}
                    </Col>
                    
                </Row>
            </Container>}
        </div>
    );
  }else{
    return (<></>);
  }
}

export default Component;
