import { useRef, useEffect } from 'react';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { FormattedNumber } from 'react-intl';
import { StarFill } from 'react-bootstrap-icons';
import Moment from 'moment';

export const DEFAULT_IMAGE:string = '/default/image-not-available-grid.jpg';

export const PROTOCOL = () => {
    if (global.window) {
        const protocol = window.location.protocol;
        return protocol + '//';
    }
    return '//';
}

export const httpCorrectUrl = (url?:string) => {
    if (url != null) {
        const re = /^(https|http):\/\//;
        return url.replace(re, PROTOCOL);
    }
    return url;
}

export const FormatCurrency = (props:any = {}) => {
    return (
        <FormattedNumber value={props?.value} style={"currency"} currencyDisplay={'symbol'} currency={"USD"}  {...props} />
    )
}

export const getSelectOption = (value: number, options, name = null) => {
    let option = {};
    if (options.length > 0) {
        options.forEach((element) => {
            if (value === element.value) {
                option = element;
                return option;
            }
        });
        if (!Object.keys(option).length) {
            option = options[0];
        }
    }
    return option;
}

export const FormatDate = (props) => {
    if (!props.value) {
        return (<></>)
    }
    Moment.locale('en-us');
    let dateTimeValue = Moment(props.value).format();
    if (props.format) {
        dateTimeValue = Moment(props.value).format(props.format)
    }
    return (
        <div className="formated-date">
            {prefix(props.prefix)}{dateTimeValue}
        </div>
    )

};

const prefix = (prefix) => {
    if (prefix) {
        return (
            <span><b>{prefix}</b></span>
        )
    }

}
export const OrderStatuses = () => {
    return {
        payment_received: 'Payment Received',
        completed: 'Completed',
        canceled: 'Canceled',
    };
}




export const useIsMounted = ()=> {
    const isMounted = useRef(false);
    useEffect(() => {
      isMounted.current = true;
      return () => {isMounted.current = false};
    },[isMounted]);
  
    return isMounted;
}

export const isNearRetail = (id:number, retail_price:number, price:number)=>{
    if(retail_price >= price ){
        return (
             <div  className='near-retail'><StarFill /></div>    
        );
    }
    return <></>
}