import React from 'react';
import { Link } from 'react-router-dom';
import {Product, Size} from "../../models"
import { Table, Image } from 'react-bootstrap';
import { FormatCurrency,httpCorrectUrl } from '../util/helper/index';
type Props = {
  item?: Size,
  release?: Product
}

// class Cart extends Component {

const Component: React.FC<Props> = ({item, release}) => {    

  if(!item || !release){
    return (<></>);
  }
  const item_total_price = (item?.product_price_raw?item.product_price_raw:0) + (item?.size_price_raw?item.size_price_raw:0);
  const src = httpCorrectUrl(release.thumb_image);
  const link = '/release/' + release.slug;

  const shopping_items = (
    <tr>
      <td>
        <Link to={link}>
              <Image width={64} className="cart-image" src={src} alt={release.title} />
        </Link>
      </td>
      <td>
        <Link to={link}>{release.title}</Link>
      </td>
      <td>{item.size}</td>
      <td><FormatCurrency value={item_total_price} /></td>
    </tr>
  )

  return (
    <div id='shopping-cart'>
      <Table>
        <thead>
          <tr>
            <th colSpan={2}>Title</th>
            <th>Size</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>{shopping_items}</tbody>
      </Table>
    </div >
   );

}

export default Component;
