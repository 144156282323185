import React, {useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';
import {Col, Container, Row} from "react-bootstrap";
import {BallTriangle} from 'react-loader-spinner';
import {motion} from "framer-motion";
import {OrderListApi} from "../../api/order-api-service";
import OrderItem from "./item";
import NFTPagination from "../util/helper/pagination";
import {Item} from "../../models/order"
import "./styles.scss"

type Props = {
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,       
}

const Component: React.FC<Props> = ({ currentAccount, setCurrentAccount, token, setToken}) => {
    const [page, setPage] = useState<number>(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [perPage, setPerPage] = useState<number>(24);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [order, setOrder] = useState<string>('popular');
    const [orders, setOrders] = useState<Item[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [noresult, setNoresult] = useState<boolean>(false);
    
    useEffect(() => {
        const currentPage = searchParams.get('page') || '1';
        if(page!== parseInt(currentPage)){
            setPage(parseInt(currentPage));
        }
        const currentOrder = searchParams.get('order') || order;
        if(currentOrder && order!== currentOrder){
            setOrder(currentOrder);
        }          
    },[searchParams, page, order]);

    useEffect(() => {
        
        const fetchAPI = async () => {
            if(!loading && token){
                setLoading(true);
                const response = await OrderListApi(page, perPage, {}, token);
                if(response.success && response?.data && response?.data?.total &&  response?.data?.items && response?.data?.items.length>0){
                    setNoresult(false);
                    setOrders(response?.data?.items);
                    setTotalPages(Math.ceil(response?.data?.total / perPage));
                }else{
                    setNoresult(true);
                }
                setLoading(false);
            }
        }
        fetchAPI();
    },[token, page, order, perPage]);

    const decorateItems = () => {
        const result = orders.length > 0 ? orders.map((order, index) => {
            return (
                <OrderItem item={order} key={index} />
            );
        }) : noresult ? (
            <Col lg={12} className="noresult">No result found.</Col>
        ) : '';    
        return result; 
    }

    const pagination = ()=>{
        if(totalPages > 1){ 
            return (
                <Col lg={12} id="pagination-wrapper">
                    <NFTPagination
                        totalPages={totalPages}
                        currentPage={page}
                        pageClicked={(ele:string) => {
                            setSearchParams({ page : ele})
                        }} />
                </Col>
        )}
        return <></>;
    }

    return (
        <div className="mainpage-wrapper-container pt-5 pb-5 order-list-section w-100 overflow-hidden">
            <Container>
                <Row className='pb-5'>
                    <motion.h1
                    initial={{ y: -80, scale: 0.5 }}
                    whileInView={{ y: 0, scale: 1 }}
                    transition={{
                        type: 'circIn',
                        ease: 'anticipate',
                        duration: 1
                    }}
                    className="text-left">My Orders</motion.h1>
                </Row>                
                <Row className="justify-content-left">
                    {loading &&
                        <Col md={12} className="text-center">
                            <BallTriangle wrapperClass="d-inline-flex" color="#eb6864" height={80} width={80}/>
                        </Col>
                    }
                    {!loading && decorateItems()}
                    {!loading && pagination()}
                </Row>
            </Container>
        </div>
    )
}

export default Component;