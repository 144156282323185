import React, {useState, useEffect } from 'react';
import { Col, Table, Form, Button, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import Googleplaces from './googleplaces';
import { EstimateDeliveryApi, EstimateTaxApi, CountriesApi, ProvincesApi, CheckoutApi } from "../../api/kixify-api-service";
import {Product, Size, Address, Country, Province, EstimateTax} from "../../models"
import { FormatCurrency, getSelectOption } from '../util/helper/index';
import Cart from './cart';

const componentForm = {
  country: { name: 'country', type: 'short_name', default: 840 },
  street_number: { name: 'street1', type: 'long_name', default: '' },
  street_address: { name: 'street1', type: 'long_name', default: '' },
  route: { name: 'street2', type: 'long_name', default: '' },
  sublocality_level_1: { name: 'street2', type: 'long_name', default: '' },
  locality: { name: 'city', type: 'long_name', default: '' },
  postal_code: { name: 'postal', type: 'long_name', default: '' },
  postal_code_suffix: { name: 'postal', type: 'long_name', default: '' },
  administrative_area_level_1: { name: 'province', type: 'long_name', default: '' }
};

const initState = {
  selected_country: 0,
  auto_province: '',
  address: {
    firstname:'',
    lastname:'',
    email:'',
    country: 840,
    province: 0,
    city: '',
    street1: '',
    street2: '',
    postal: '',
    phone: '',
    memo: ''
  },
  calcualte_tax: 0,
  // search: '',
};

type Props = {
  currentAccount: string,
  setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
  token: string,
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>,  
  release: Product,
  item: Size
}


// class Checkout extends Component {
const Component: React.FC<Props> = ({currentAccount, setCurrentAccount, token, setToken, release, item}) => {    
  const [shippingAddress, setShippingAddress] = useState<Address>(initState.address);
  const [autoProvice, setAutoProvince] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<number>(0);
  const [selectedProvice, setSelectedProvice] = useState<number>(0);
  const [calcualteTax, setCalcualteTax] = useState<number>(0);
  const [estimatedTax, setEstimatedTax] = useState<EstimateTax>({isLoading:false});
  const [estimatedDelivery, setEstimatedDelivery] = useState<string>("");
  // const [search, setSearch] = useState<string>('');
  const [place, setPlace] = useState<Object>({});
  const [countries, setCountries] = useState<Country[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => { 
    const fetchMyAPI = async () => {
      let response = await CountriesApi(token);
      if (response.data.items.length > 0) {
        setCountries(response.data.items);
      } else {
        setCountries([]);
      }
    }
    fetchMyAPI();
  }, []);
  
  useEffect(() => {
    const fetchMyAPI = async () => {
      if (calcualteTax || (shippingAddress?.postal !== undefined &&  shippingAddress?.postal?.length>0)) {
        let response = await EstimateTaxApi(item, shippingAddress, token);
        if (response.success && response.data.tax.id) {
          let taxObj = Object.assign({isLoading:false}, response.data);
          setEstimatedTax(taxObj);
        } else {
          setEstimatedTax({isLoading:false});
        }
      } else {
        setEstimatedTax({isLoading:false});
      }
      setCalcualteTax(0);
    }
    fetchMyAPI();
  }, [item, calcualteTax,shippingAddress?.postal, shippingAddress?.province,shippingAddress?.city]); 

  useEffect(() => {
    const fetchMyAPI = async () => {
      if (item?.nid) {
        let response = await EstimateDeliveryApi(item?.nid, {}, token);
        if (response.data.estimated_delivery.length > 0) {
          setEstimatedDelivery(response.data.estimated_delivery);
        } else {
          setEstimatedDelivery("");
        }
      } else {
        setEstimatedDelivery("");
      }
    }
    fetchMyAPI();
  }, [item]);

  useEffect(() => {
    const fetchMyAPI = async () => {
      if (shippingAddress?.country !== undefined && shippingAddress?.country>0) {
        let response = await ProvincesApi(shippingAddress.country, token);
        if (response.data.items.length > 0) {
          setProvinces(response.data.items);
          if(autoProvice.length>0){
            let tmpShippingAddress = Object.assign({}, shippingAddress);
            for (var j = 0; j < response.data.items.length; j++) {
              if (autoProvice.toUpperCase() === response.data.items[j]?.name.toUpperCase()) {
                const province_value = response.data.items[j].id;
                tmpShippingAddress.province = province_value;
                setSelectedProvice(province_value);
                break;
              }
            }
            setShippingAddress(tmpShippingAddress);
          }
          setAutoProvince('');
        } else {
          setProvinces([]);
        }
      } else {
        setProvinces([]);
      }
    }
    fetchMyAPI();
  }, [shippingAddress.country]);

  useEffect(() => {
    const fetchMyAPI = async () => {
      await onPlaceChange(place);
    }
    fetchMyAPI();
  }, [place]);


  const handleInputChange= (event) =>{
    event.preventDefault();
    const value = event?.target?.value;
    const address = Object.assign({}, shippingAddress, {
      street1: value
    });
    setShippingAddress(address);
    // setSearch(value);
  }
  const onPlaceSelected = (place, inputRef, autocomplete)=> {
    setPlace(place);
  }

  const onPlaceChange = async (place)=> {
    if(place.hasOwnProperty('address_components')){
      setAutoProvince('');
      setSelectedProvice(0);
      let tmpShippingAddress = Object.assign({}, initState.address, {firstname:shippingAddress.firstname, lastname:shippingAddress.lastname, email:shippingAddress.email, phone:shippingAddress.phone, memo:shippingAddress.memo});
      let provinceval = '';
      let address = '';
      let postal_code = '';
      let received_component:Array<string> = [];
      for (let j = 0; j < place?.address_components?.length; j++) {
        let addressType = place?.address_components[j].types[0];
        received_component[addressType] = place?.address_components[j];
      }

      for (let addressType in componentForm) {
        if (received_component[addressType]) {
          let element_name = componentForm[addressType].name;
          var val = received_component[addressType][componentForm[addressType].type];
          switch (addressType) {
            case 'country':
              var currentCountry = val.toUpperCase();
              var options = countries;
              for (var i = 0; i < options.length; i++) {
                if (options[i].iso_code_2 === currentCountry) {
                  if (shippingAddress.country !== options[i].id) {
                    const country_value = options[i].id;
                    setSelectedCountry(country_value);
                    tmpShippingAddress.country = country_value;
                  } else {
                    if (received_component['administrative_area_level_1']) {
                      provinceval = received_component['administrative_area_level_1'][componentForm['administrative_area_level_1'].type].toUpperCase();
                      for (var j = 0; j < provinces.length; j++) {
                        if (provinceval === provinces[j]?.name.toUpperCase()) {
                          const province_value = provinces[j].id;
                          tmpShippingAddress.province = province_value;
                          setSelectedProvice(province_value);
                          break;
                        }
                      }
                    }
                  }
                  break;
                }
              }
              break;
            case 'administrative_area_level_1':
              if (provinceval === '') {
                setAutoProvince(val.toUpperCase());
                tmpShippingAddress.province = 0;
              }
              break;
            case 'street_number':
              address += val;
              break;
            case 'route':
              address += ' ' + val;
              break;
            case 'street_address':
              address += ' ' + val;
              break;
            case 'postal_code':
              postal_code += val;
              break;
            case 'postal_code_suffix':
              postal_code += '-' + val;
              break;
            default:
              tmpShippingAddress[element_name] = val;
              break;
          }
        }
      }
      tmpShippingAddress.street1 = address;
      tmpShippingAddress.postal = postal_code;
      setShippingAddress(tmpShippingAddress); 
    }
  }

  const handleChange = (event) =>{
    let value = event.target.value;
    let name = event.target.name;
    let current_address = Object.assign({}, shippingAddress, {
      [name]: value
    });
    setShippingAddress(current_address)
  }

  const handleTaxCalculate=(event) =>{
    let calcualte_tax = 1;
    if (typeof event !== 'undefined' && event.target) {
      if (event.target.name === 'postal' && event.target.value === shippingAddress.postal) {
        calcualte_tax = 0;
      }
    }
    setCalcualteTax(calcualte_tax);
  }

  const handleSelectChange = (name, is_user_input, event)=> {
    const value = parseInt(event.value);
    switch (name) {
      case 'country':
        let address1 = Object.assign({}, shippingAddress, {
          country:value,
          province: 0
        });
        setSelectedCountry(value);
        setShippingAddress(address1);
        break;
      case 'province':
        let address2 = Object.assign({}, shippingAddress, {
          province: value
        });
        setSelectedProvice(value);
        setShippingAddress(address2);
        setCalcualteTax(is_user_input ? 1 : 0);
        break;
    }
  }

  const onKeyPress = (event:any) =>{
    if (event.which === 13) {
      event.preventDefault();
    }
  }
  const detectCountryShipping=(country : number) =>{
    let ship_country = 'us';
    switch (country) {
      case 840:
        ship_country = 'us'
        break;
      case 124:
        ship_country = 'ca'
        break;
      default:
        ship_country = 'in'
        break;
    }
    if (item?.shipping && item?.shipping[ship_country] === -1) {
      // this.props.no_shipping();
    } else if (shippingAddress.country !== country) {
      // this.props.clearAlerts();
    }
  }

  const handleSubmit=(event) =>{
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setErrors([]);
    setValidated(true);
    event.preventDefault();
    const checkout = async () =>{
      let response = await CheckoutApi(currentAccount, item, release, shippingAddress, token);
      if(response.success && response.data.url){
        window.location.href = response?.data?.url;
      }else{
        if(response.errors.length>0){
          let errorsList = response.errors.map((error)=>{
            return error.msg;
          });
          setErrors(errorsList);
        }
      }
    }
    checkout();
  }

  const FieldGroup= ({ id, label = false, help = false, require =true, errorMsg = '', ...props }) =>{
    if(require){
      props = {...props, 'required':'required'}
    }
    return (
      <Col xs={12} sm={6} md={6} className='checkout-field' id={id+'-field-wrapper'}>
        <Form.Group controlId={id}>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.Control  {...props} />
          {help && <Form.Text muted>{help}</Form.Text>}
          {errorMsg && <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>}
        </Form.Group>
      </Col>
    );
  }

  const FieldSelectGroup = ({ id, label = false, help = false, errorMsg = '', ...props })=> {
    return (
      <Col xs={12} sm={6} md={6} className='checkout-field-select' id={id+'-field-wrapper'}>
        <Form.Group controlId={id}>
          {label && <Form.Label>{label}</Form.Label>}
          <Select {...props} />
          {help && <Form.Text muted>{help}</Form.Text>}
          {errorMsg && <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>}
        </Form.Group>
      </Col>
    );
  }
    if (!Object.keys(item).length) {
      return (
        <div id="checkout-wrapper">
          <h1>Checkout</h1>
          {/* <Alerts alerts={this.props.alerts} /> */}
          <div>You don't have product for checkout.</div>
        </div>
      )
    }
    const countriesList = countries.length > 0 ? countries.map((item, index) => {
      return { value: item.id, label: item.name }
    }) : [];
    const provincesList = provinces.length > 0 ? provinces.map((item, index) => {
      return { value: item.id, label: item.name };
    }) : [];
    provincesList.unshift({ value: 0, label: 'Select province' });
    let estimated_tax = 0;
    if (!estimatedTax.isLoading && estimatedTax.hasOwnProperty('tax')) {
      estimated_tax = estimatedTax?.tax?.amount? estimatedTax.tax.amount : 0;
    }
    let ship_country = 'us';
    switch (shippingAddress.country) {
      case 840:
        ship_country = 'us'
        break;
      case 124:
        ship_country = 'ca'
        break;
      default:
        ship_country = 'in'
        break;
    }

    let total = 0;
    let shipping = 0;
    const tax_amount = estimated_tax;
    const item_total_price = parseFloat(item?.product_price?item.product_price.toString():'0') + parseFloat(item?.size_price?item.size_price.toString():'0');
    total += item_total_price;
    let ship_text = <>???</>;
    if (item?.shipping && parseFloat(item?.shipping[ship_country].toString()) > -1) {
      shipping += parseFloat(item?.shipping[ship_country].toString());
      total += shipping;
      ship_text = <FormatCurrency value={shipping} />;
    }

    total += tax_amount;
    const tax_text = estimatedTax.isLoading ? <span id="tax-estimate-spinner"><i className={"fa fa-refresh fa-fw fa-spin"} onClick={(e)=>handleTaxCalculate(e)}></i><span className="sr-only">Loading...</span></span> : <FormatCurrency value={tax_amount} />;
    const selected_country = shippingAddress?.country? getSelectOption(shippingAddress.country, countriesList):840;
    const selected_provice = shippingAddress?.province? getSelectOption(shippingAddress.province, provincesList):0;
    
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Checkout</Modal.Title>
        </Modal.Header>  
        <Modal.Body>    
          <Cart item={item} release={release} />
          <h4>Shipping Address</h4>
          <hr />
          {errors && errors.length>0  && <Alert variant={'danger'}>{errors.map((error, index) => (<div key={index}>{error}</div>))}</Alert> }
          <Form onKeyPress={(e)=>onKeyPress(e)}  validated={validated} id="checkout-form" onSubmit={(e)=>handleSubmit(e)} className="form-horizontal row" noValidate >
            {FieldGroup({
              id: 'firstname',
              name: 'firstname',
              as: "input",
              placeholder: 'First Name',
              value: shippingAddress.firstname,
              onChange: (e)=>handleChange(e),
              required:true,
              errorMsg: 'Please provide a valid first name.'
            })}
            {FieldGroup({
              id: 'lastname',
              name: 'lastname',
              as: "input",
              placeholder: 'Last Name',
              value: shippingAddress.lastname,
              onChange: (e)=>handleChange(e),
              required: true,
              errorMsg: 'Please provide a valid last name.'
            })}
            <Col xs={12} sm={6} md={6}>
              <Form.Group>
                {Googleplaces({
                  type:"input", 
                  onChange: (e)=>handleInputChange(e),
                  name:"street1", 
                  id:"street1", 
                  placeholder:"Street Address or P.O. Box", 
                  value: shippingAddress.street1,
                  require: 'required',
                  feedback: 'Please provide a valid address.'
                }, (place,inputRef, autocomplete)=>onPlaceSelected(place, inputRef, autocomplete) )
                } 
              </Form.Group>
            </Col>
            {FieldGroup({
              id: 'street2',
              name: 'street2',
              as: "input",
              placeholder: 'Apt, Suite, Unit, Building, Floor',
              value: shippingAddress.street2,
              onChange: (e)=>handleChange(e),
              require: false
            })}
            {FieldSelectGroup({
              id: 'country',
              name: 'country',
              className: "react-select-container",
              classNamePrefix: 'react-select',
              isClearable: false,
              defaultValue: selected_country,
              value: selected_country,
              onChange: (e)=>handleSelectChange('country', true, e),
              options: countriesList
            })}
            {FieldSelectGroup({
              id: 'province',
              name: 'province',
              className: "react-select-container",
              classNamePrefix: 'react-select',
              isClearable: false,
              defaultValue: selected_provice,
              value: selected_provice,
              onChange: (e)=>handleSelectChange('province', true, e),
              options: provincesList,
              errorMsg: 'Please provide a valid state.'
            })}
            {FieldGroup({
              id: 'city',
              name: 'city',
              as: "input",
              placeholder: 'City',
              value: shippingAddress.city,
              onChange: (e)=>handleChange(e),
              required: true,
              errorMsg: 'Please provide a valid city.'
            })}
            {FieldGroup({
              id: 'postal',
              name: 'postal',
              as: "input",
              placeholder: 'Postal Code',
              value: shippingAddress.postal,
              onBlur: (e)=>handleTaxCalculate(e),
              onChange: (e)=>handleChange(e),
              required: true,
              errorMsg: 'Please provide a valid postal code.'
            })}
            {FieldGroup({
              id: 'email',
              type: "email",
              as: "input",
              name: 'email',
              placeholder: 'Email',
              value: shippingAddress.email,
              onChange: (e)=>handleChange(e),
              required: true,
              errorMsg: 'Please provide a valid email.'
            })}
            {FieldGroup({
              id: 'phone',
              name: 'phone',
              as: "input",
              placeholder: 'Phone Number',
              value: shippingAddress.phone,
              onChange: (e)=>handleChange(e),
              require: false
            })}
            <Col xs={12} sm={12} md={12}>
              <Table className="pull-right" style={{ width: "auto" }}>
                <tbody>
                  <tr className={estimatedDelivery.length ? '' : 'hidden'}>
                    <td style={{ textAlign: "right", borderTop: "0", borderBottom: "1px" }}>Estimated Delivery</td>
                    <td style={{ borderTop: 0, borderBottom: "1px" }}>{estimatedDelivery}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", borderTop: "0" }}>Shipping</td>
                    <td style={{ borderTop: "0" }}>{ship_text}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>Estimated Sales Tax</td>
                    <td>{tax_text}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>Total</td>
                    <td><FormatCurrency value={total} /></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs={12} sm={12} md={12}></Col>
         </Form>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group>
            <Button type="button" className="pull-right" onClick={(e)=>handleSubmit(e)}>Pay Now</Button>
          </Form.Group>            
        </Modal.Footer>
      </>
    );
}

export default Component;