import React, {useState} from 'react';
import "./styles.scss"

type Props = {
    value: number;
    onChangeValue: (value: number) => void
}

const NumberInputComponent: React.FC<Props> = ({value, onChangeValue}) => {

    const [currentValue, setCurrentValue] = useState<number>(value);

    const changeCurrentValue = (value: number) => {
        setCurrentValue(value);
        onChangeValue(value);
    }

    return (
        <div className="input-number">
            <button type="button"
                    onClick={() => changeCurrentValue(currentValue - 1 > 0 ? currentValue - 1 : 0)}>&minus;</button>
            <span>{currentValue}</span>
            <button type="button" onClick={() => changeCurrentValue(currentValue + 1)}>&#43;</button>
        </div>
    )
}

export default NumberInputComponent;