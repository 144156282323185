import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Image, Table, Container } from 'react-bootstrap';
import {motion} from "framer-motion";
import {BallTriangle} from 'react-loader-spinner';
import {OrderViewApi} from "../../api/order-api-service";
import {DEFAULT_IMAGE, FormatCurrency, FormatDate, OrderStatuses, httpCorrectUrl } from '../util/helper';
import {Order, ProductItem} from '../../models/order';
import OrderMessages from './messages';
import "./styles.scss"



type Props = {
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,       
}

const Component: React.FC<Props> = ({ currentAccount, setCurrentAccount, token, setToken}) => {
    let {orderId} = useParams();
    const [order, setOrder] = useState<Order>();
    // const [messages, setMessages] = useState<Object>([]);
    const [noresult, setNoresult] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchAPI = async () => {
            if(!loading){
                setLoading(true);
                let response = await OrderViewApi(orderId, token);
                if(response?.success && response?.data?.hasOwnProperty('orderId')){
                    setOrder(response?.data);
                }else{
                    setNoresult(true);
                }
                setLoading(false);    
            }
          }        
        fetchAPI();
      },[token, orderId]);

    // if (!order?.orderProductItems) {
    //     if (loading) {
    //         return (<></>)
    //     } else {
    //         return (<></>
    //             // <Alerts alerts={this.props.alerts} />
    //         )
    //     }
    // }
    const order_statuses = OrderStatuses();

    const products = order !== undefined && order.orderProductItems.length > 0 ? order.orderProductItems.map((product:ProductItem, index:number) => {
        const releaseLink = product.uri !== null ? '/release/' + product.uri : '#';
        const img = product?.images != null && typeof(product?.images) != 'string' && product?.images.length>0 && product?.images[0] !== null ? product?.images[0] : DEFAULT_IMAGE;
        const src = httpCorrectUrl(img)?.replace('0.app', '0app');
        return (
            <tr key={index}>
                <td>
                    <Link to={releaseLink}>
                        <Image width={64} src={src} alt="Image" />
                    </Link>
                </td>
                <td>
                    <h5><Link to={releaseLink}>{product?.name}</Link></h5>
                    <div>Brand New. {product?.styleCode}</div>
                </td>
                <td>{product?.size}</td>
                <td>{product?.qty}</td>
                <td><FormatCurrency value={(product?.price ?product.price:0) * (product?.qty ?product.qty:0)} /></td>
            </tr>
        );
    }) : <></>;


    return (
        <div className="mainpage-wrapper-container pt-5 pb-5 order-view-section w-100 overflow-hidden">
            {loading &&
                <Row className="text-center">
                    <Col md={12} className="text-center">
                        <BallTriangle wrapperClass="d-inline-flex" color="#eb6864" height={80} width={80}/>
                    </Col>
                </Row>
            }
            {order!== undefined &&  !loading && <Container>
                <Row className='pb-5'>
                    <motion.h1
                    initial={{ y: -80, scale: 0.5 }}
                    whileInView={{ y: 0, scale: 1 }}
                    transition={{
                        type: 'circIn',
                        ease: 'anticipate',
                        duration: 1
                    }}
                    className="text-left">Order #{order?.orderId}</motion.h1>
                </Row>
                <Row id="order-view-wrapper" className="justify-content-center">
                    <Col xs={12} sm={12} md={12}>
                        {/* <Alerts alerts={this.props.alerts} /> */}
                    </Col>
                    <Col xs={12} sm={4} md={4}>
                        <div id="order-status"><b>Order Status: </b>{order_statuses[order.orderStatus]}</div>
                        <div id="order-date"><FormatDate value={order.orderCreated} format="lll" prefix="Order Date: " /></div>
                        {expectedDelivered(order)}
                        {tracking(order)}
                        
                        <div id="shipping-address">
                            <h3>Shipping Address</h3>
                            <div>
                                {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                            </div>
                            <div>
                                {order.shippingAddress.street1} {order.shippingAddress.street2} {order.shippingAddress.city}, {order.shippingAddress.stateCode} {order.shippingAddress.postalCode}, {order.shippingAddress.countryIsoCode2}
                            </div>
                            <div>
                                {order.shippingAddress.phoneNumber}
                            </div>
                        </div>
                        <hr />
                    </Col>
                    <Col xs={12} sm={8} md={8}>
                        <Table className="table">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Product</th>
                                    <th>Size</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products}
                                <tr><td colSpan={4} className="td-text-align">Shipping</td><td><FormatCurrency value={order.orderShipping} /></td></tr>
                                <tr><td colSpan={4} className="td-text-align">Taxes</td><td><FormatCurrency value={order.orderTax ? order.orderTax : 0} /></td></tr>
                                <tr><td colSpan={4} className="td-text-align">Total</td><td><FormatCurrency value={order.orderTotal} /></td></tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <OrderMessages 
                        orderId={order.orderId} 
                        setCurrentAccount={setCurrentAccount}
                        currentAccount={currentAccount}
                        setToken={setToken}
                        token={token} />
                    </Col>
                </Row>
            </Container>}
        </div>
    );
}

const expectedDelivered = (order:Order) => {
    if (!order.delivered) {
        if (order.expectedDelivery) {
            return (
                <div id="order-expected">
                    <span className="item-txt"><b>Expected Delivery: </b></span> <span className="item-value">{order.expectedDelivery}</span>
                </div>
            )
        }
    } else {
        return (
            <div id="order-delivered">
                <span className="item-txt"><b>Delivered: </b></span> <span className="item-value">{order.deliveredDate}</span>
            </div>
        )
    }
    return (
        <div />
    )
};
const tracking = (order:Order) => {
    if (order.trackingNumber) {
        let trackingNumber = <span>{order.trackingNumber}</span>;
        if (order.trackingUrl) {
            trackingNumber = <Link target="blank" to={order.trackingUrl}>{trackingNumber}</Link>;
        }
        return (
            <div id="order-tracking">
                <span className="item-txt">Tracking Number: </span> <span className="item-value">{trackingNumber}</span>
            </div>
        )
    }
    return (
        <div />
    )
};
export default Component;
