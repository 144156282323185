import { useState } from "react";
import { clearCacheProvider, connectCacheWallet, getCurrentAccount } from "../network/ethereum";
import { checkAccountConnect } from '../api/api-service';

export const useWallet = () => {

    const [currentAccount, setCurrentAccount] = useState<string | undefined>(undefined);
    const [token, setToken] = useState<string | undefined>(undefined);
    const init = async () => {
        try {
            let provider = await connectCacheWallet();
            if (provider == null) {
                return;
            }
            if (provider.on) {
                provider.on("accountsChanged", async (accounts: any) => {
                    if (accounts.length === 0) {
                        clearCacheProvider();
                        setCurrentAccount(undefined);
                        setToken(undefined);
                    }
                });
            }

            const account = await getCurrentAccount();
            const connectedAccount = await checkAccountConnect(account);
            setCurrentAccount(account);
            setToken(connectedAccount?.data?.token);
        } catch (err) {
            console.log(err);
        }
    }

    init();

    return { currentAccount, setCurrentAccount ,token, setToken };
}