import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Form, FormGroup, FormControl, Row, Col, Button } from 'react-bootstrap';
import {BallTriangle} from 'react-loader-spinner';
import {OrderMessagesApi, OrderMessageSendApi} from "../../api/order-api-service";
import {FormatDate} from '../util/helper';
import { Message} from '../../models/order';

type Props = {
    orderId:number,
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,       
}

const Component: React.FC<Props> = ({ orderId, currentAccount, setCurrentAccount, token, setToken}) => {
    const scrollBarRef = useRef<Scrollbars>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [message, setMessage] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    
    const [noresult, setNoresult] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setSubject('Order #' + orderId);
        handleMessages();
    },[token, orderId]);

    useEffect( () => {
        handleScrollUpdate();
    }, [messages]);

    const handleMessages= async ()=>{
        if(!loading){
            setLoading(true);
            let response = await OrderMessagesApi(orderId, token);
            if(response.success && response?.data?.items){
                setMessages(response?.data?.items);
            }else{
                setNoresult(true);
            }
            setLoading(false);
        }
    }

    const handleChange=(event) =>{
        setMessage(event.target.value );
    }

    const handleScrollUpdate= ()=> {
        if(scrollBarRef.current!= null){
            scrollBarRef.current.scrollToTop();
        } 
    }

    const handleSubmit= async (event) =>{
        event.preventDefault();
        if (message.trim().length !== 0) {
            await OrderMessageSendApi(orderId, message.trim(), subject.trim(), token);//, this.props.influencer, this.props.token);
            setMessage('');
            await handleMessages();
            handleScrollUpdate();
        }
        return false;
    }
    const messageList = messages ? messages.map((message, index) => {
        let wrclass = 'order-message-item-wrapper msg-content-right';
        let message_owner = 'Agent:';
        // if (message.sender && message.sender.platformUserId == currentAccount.uid) {
        //     wrclass = 'order-message-item-wrapper msg-content-left';
        //     message_owner = 'Me:';
        // }
        return (
            <div key={message.id}>
                <div className={wrclass}>
                    <div className="message-date"><div className='msg-author'>{message_owner}</div><FormatDate value={message.created} format="MM/DD/YYYY h:mm A" /></div>
                    <div className="message-body">{message.messageText}</div>
                </div>
                <div className="clearfix" />
            </div>
        )
    }) : '';
    return (
        <div id="order-messages-wrapper">
            <h3>Messages<span><i></i></span></h3>
            <Row>
                <Col xs={12} sm={6} md={7} id="order-messages-container">
                    <Scrollbars
                        ref={scrollBarRef}
                        id="message-scroll-content"
                        autoHeight
                        autoHeightMin={100}
                        autoHeightMax={200}
                        hideTracksWhenNotNeeded={true}>
                        {loading &&
                            <Col md={12} className="text-center">
                                <BallTriangle wrapperClass="d-inline-flex" color="#eb6864" height={50} width={50}/>
                            </Col>
                        } 
                        {messageList}
                    </Scrollbars>
                </Col>
                <Col xs={12} sm={6} md={5} id="order-send-message-wrapper">
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <FormGroup>
                            <FormControl
                                as="textarea"
                                name="message"
                                placeholder="Write your question about order here..."
                                id="message"
                                rows={4}
                                value={message}
                                onChange={(e) => handleChange(e)} />

                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col xs={12} sm={4} md={4} className="pull-right">
                                    <Button type='submit' bsPrefix='btn' className="btn-bears-dark pull-right" onClick={(e) => handleSubmit(e)}>Send</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default Component;
