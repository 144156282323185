import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { DEFAULT_IMAGE, FormatCurrency, FormatDate, OrderStatuses, httpCorrectUrl } from '../util/helper';
import {Item} from "../../models/order";


type Props = {
    item: Item,
}
const Component: React.FC<Props> = ({ item}) => {
    const orderLink = '/order/' + item.orderId;
    let img = DEFAULT_IMAGE;
    if(item?.orderProductItems.length>0){
        if(item.orderProductItems[0].images != null){
            img = typeof(item.orderProductItems[0].images)=='string'?item.orderProductItems[0].images:item.orderProductItems[0].images[0];
        }
    }
    const src = httpCorrectUrl(img);
    const productName = item?.orderProductItems.length>0? item.orderProductItems[0].name:'';
    const orderTitle = "Order #" + item.orderId;
    const order_statuses = OrderStatuses();
    const order_status = order_statuses[item?.orderStatus];

    return (
        <Col xs={12} sm={12} md={6} className="order-item">
            <Card className="order-item-wrapper">
                <Card.Header className='row'>
                    <Col xs={6} sm={6} md={6} className='order-txt text-start clear-paddings'><Link to={orderLink}>{orderTitle}</Link></Col>
                    <Col xs={6} sm={6} md={6} className='order-date text-end clear-paddings'><FormatDate value={item.orderCreated} format="lll" /></Col>
                </Card.Header>
                <Row>
                    <Col xs={3} sm={3} className="clear-padding-right">
                        <Link to={orderLink} className="order-image"><img src={src} alt={productName} className="img-fluid" /></Link>
                    </Col>
                    <Col xs={9} sm={9} className="clear-paddings">
                        <Card.Body>
                            <Card.Text><Link to={orderLink}>{productName}</Link></Card.Text>
                            <Card.Text></Card.Text>
                        </Card.Body>
                    </Col>
                </Row>
                <Card.Footer className='order-item-status row'>
                    <Col xs={6} sm={6} md={6} className="text-start"><b>Status:&nbsp;</b>{order_status}</Col>
                    <Col xs={6} sm={6} md={6} className="text-end"><Link to={orderLink}><b>Total:&nbsp;</b><FormatCurrency value={item.orderTotal} /></Link></Col>
                </Card.Footer>
            </Card>
        </Col>
    );
}

export default Component;// withRouter(OrderItem);
