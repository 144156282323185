import React from 'react';
import {Container, Row} from "react-bootstrap";
import logo from "../../images/bears-club-white.png";

import discord from "../../images/social-media-icons/discord.svg";
import instagram from "../../images/social-media-icons/instagram.svg";
import twitter from "../../images/social-media-icons/twitter.svg";

import "./styles.scss";

type Props = {
}

const Component: React.FC<Props> = () => {
    return (
        <div className="copyright-container pb-5 overflow-hidden">
            <Container>
                <Row className="mt-5">
                    <img className="copyright-logo mb-3" src={logo} alt="Bear Club" />
                    <h6 className="text-white-50 text-center">Copyright &copy; 2022 KicksOnFire.com</h6>
                    <div className="social-media-section text-center mt-3">
                        <a href="http://discord.gg/dVRpFfMfrF">
                            <img className="social-media-icon" src={discord}  alt="Discord" />
                        </a>
                        <a href="https://www.instagram.com/kicksonfire">
                            <img className="social-media-icon" src={instagram} alt="Instagram" />
                        </a>
                        <a href="https://twitter.com/kicksonfire">
                            <img className="social-media-icon" src={twitter} alt="Twitter" />
                        </a>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default Component;