import React, {useState, useEffect} from 'react';
import {Container,Col, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {motion} from "framer-motion";
import {BallTriangle} from 'react-loader-spinner';
import {ReleaseViewApi} from "../../api/kof-api-service";
import {httpCorrectUrl} from "../util/helper";
import Related from './related';
import BuyNow from './buynow';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";

import {Product, RelatedItems, Size} from "../../models"

import "./styles.scss";

type Props = {
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>,
    token?: string,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>,        
}

const ReleaseGallery = (props:any) => {
    return (
        <ImageGallery
            items={props.images}
            slideInterval={1000}
            showPlayButton={false}
            disableThumbnailScroll={false}
            disableArrowKeys={true}
            showBullets={true}
            showThumbnails={false}
            lazyLoad={true}
            showNav={true}
            useBrowserFullscreen={false}
            showFullscreenButton={true}
            thumbnailPosition='top'
        />
    );
}
export const ImageSlider = ReleaseGallery;

const Component: React.FC<Props> = ({ currentAccount, setCurrentAccount, token, setToken}) => {

    // const [error,setError] = useState(false);
    // const [cart, setCart] = useState<CartItem[]>([]);
    let {slug} = useParams();
    const [product, setProduct] = useState<Product>();
    const [related, setRelated] = useState<RelatedItems>();
    const [sizes, setSizes] = useState<Size[]>([]);
    const [gallery_images, setGalleryImages] = useState<Object>([]);
    const [noresult, setNoresult] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchAPI = async () => {
            if(!loading){
                setLoading(true);
                let response = await ReleaseViewApi(slug, token);
                if(response.success && response?.data?.release?.hasOwnProperty('id')){
                    setProduct(response.data.release);
                    setRelated(response.data.related);
                    setSizes(response.data.sizes);
                    const images = [httpCorrectUrl(response.data.release.master_image), ...response.data.release.images];
                    const galleryImages = images.map((image, index) => {
                            return {
                                original: httpCorrectUrl(image),
                            }
                    });
                    setGalleryImages(galleryImages);
                }else{
                    setNoresult(true);
                }
                setLoading(false);    
            }
          }        
        fetchAPI();
      },[token, slug]);
    

      let relatedView:React.ReactElement = <></>;
      if(related?.total){
        relatedView = <Col xs={12} sm={12} md={12} className='clearfix mt-5  release-collection-section' id="releated-container"><Related items={related.items} country={'us'} /></Col>;
      }


    return (
        <div className="mainpage-wrapper-container pt-5 pb-5 release-detail-section  w-100 overflow-hidden">
            <Container className="">
                <Row  className="justify-content-center">
                    { loading && <Col md={12} className="text-center"><BallTriangle  wrapperClass="d-inline-flex text-center" color="#eb6864" height={80} width={80}/></Col> }
                    {!loading && !noresult && product !== undefined && Object.keys(product).length &&
                        <>
                            <Col xs={12} sm={12} md={12}>
                                <motion.h1 
                                    initial={{ y: -70, scale: 0.5 }}
                                    whileInView={{ y: 0, scale: 1 }}
                                    transition={{
                                        type: 'circIn',
                                        ease: 'anticipate',
                                        duration: 1
                                    }}
                                    className="text-center pb-5">{product ? product.title : ''}
                                </motion.h1> 
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                {/* <Alerts alerts={this.props.alerts} /> */}
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <ImageSlider images={gallery_images} />
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <Col xs={12} sm={12} md={12}>Brand New. {product.style ? product.style + "." : ""} {product.style_code ? product.style_code + "." : ""}</Col>
                                <Col xs={12} sm={12} md={12}>
                                    <BuyNow release_sizes={sizes} release={product} currentAccount={currentAccount}  setCurrentAccount={setCurrentAccount} token={token}  setToken={setToken} />
                                </Col>
                                <Col xs={12} sm={12} md={12} className="description"><p>{product.description}</p></Col>
                            </Col>
                            {relatedView}
                        </>
                    }
                </Row>
            </Container>
        </div>
    )
}

export default Component;