import { Row, Col, Button } from 'react-bootstrap';
import "./filter.scss"

const Filters = (props) => {
    const brandsList = [];
    const sizesList= [1, 2, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 16, 17, 18];
    const shippingsList = [
        {
            value: "usa",
            label: "Usa"
        },
        {
            value: "canada",
            label: "Canada"
        },
        {
            value: "world",
            label: "Worldwide"
        }];
    const gendersList= [
        {
            value: "men",
            label: "Men"
        },
        {
            value: "women",
            label: "Women"
        },
        {
            value: "youth",
            label: "Youth"
        }];


    const onBrandChange=(value:string) =>{
        let newBrands = new Set(props.brands);
        if (newBrands.has(value)) {
            newBrands.delete(value);
        } else {
            newBrands.add(value);
        }
        props.setBrands(Array.from(newBrands));
    }

    const onGenderChange=(value:string) =>{
        let newGenders = new Set(props.genders);
        if (newGenders.has(value)) {
            newGenders.delete(value);
        } else {
            newGenders.add(value);
        }
        props.setGenders(Array.from(newGenders));
    }

    const onSizeChange=(value:number) =>{
        let newSizes = new Set(props.sizes);
        if (newSizes.has(value)) {
            newSizes.delete(value);
        } else {
            newSizes.add(value);
        }
        props.setSizes(Array.from(newSizes));
    }

    const onShippingChange=(value:string) =>{
        let newShipping = "";
        if (value !== (''+props?.shipping)) {
            newShipping = value;
        }
        props.setShipping(newShipping);
    }

    const clearFilter=() =>{
        props.clearFilter();
    }

    // const brands = brandsList.length > 0 && brandsList.map((element:Object) => {
    //     if(element.hasOwnProperty('value')){
    //         let active = props?.brands && props.brands.includes(element?.value) ? " active" : ""
    //         return (
    //             <Col xs={4} key={element?.value}>
    //                 <button className={"btn btn-snkrly" + active} onClick={() => onBrandChange(element?.value)}>
    //                     {element?.label}
    //                 </button>
    //             </Col>
    //         );
    //     }else{
    //         return <></>;
    //     }
    // });
    const genders = gendersList.map(element => {
        let active = props?.genders && props.genders.includes(element.value) ? " active" : ""
        return (
            <Col xs={4} key={element.value}>
                <button className={"btn btn-snkrly" + active} onClick={() => onGenderChange(element.value)}>
                    {element.label}
                </button>
            </Col>
        )
    });

    const sizes = sizesList.map(size => {
        let active = props?.sizes && props.sizes.includes(size) ? " active" : ""
        return (
            <div key={size} className="btn-size-wrapper">
                <button className={"btn btn-snkrly" + active} onClick={() => onSizeChange(size)}>{size}</button>
            </div>
        )
    });

    const shippings = shippingsList.map(element => {
        let active = props?.shipping && (''+props.shipping) === element.value ? " active" : ""
        return (
            <Col xs={4} key={element.value}>
                <button className={"btn btn-snkrly" + active} onClick={() => onShippingChange(element.value)}>
                    {element.label}
                </button>
            </Col>
        )
    });

    return (
        <div id="filter-contianer">
            <div className="filter-header">
                <span className="name">Filter</span>
                <div className="close-btn-wrapper">
                    <Button type='button' bsPrefix='btn' size='sm' className='btn-bears-dark'   onClick={() => props.toggleSidebar()}>X</Button>
                    {/* <button className="btn btn-bears-dark" onClick={() => props.toggleSidebar()}>Close</button> */}
                </div>
            </div>
            <div id="filter-wrapper">
                {/* {brands.length > 0 ? (
                    <div id="brand-filter" className="filter-block">
                        <div className="filter-title">Brands</div>
                        <Row className="filter-group-button">
                            {brands}
                        </Row>
                    </div>
                ) : ''} */}
                {genders.length > 0 ? (
                    <div id="gender-filter" className="filter-block">
                        <div className="filter-title">Gender</div>
                        <Row className="filter-group-button">{genders}</Row>
                    </div>
                ) : ''}
                {sizes.length > 0 ? (
                    <div id="size-filter" className="filter-block">
                        <div className="filter-title">Size</div>
                        <div className="filter-group-button size">{sizes}</div>
                    </div>
                ) : ''}
                {props.api !== 'under_retail' && shippings.length > 0 ? (
                    <div id="shipping-filter" className="filter-block">
                        <div className="filter-title">Shipping</div>
                        <Row className="filter-group-button">{shippings}</Row>
                    </div>
                ) : ''}
                <hr />
                <div className="clean-btn-wrapper">
                    <Button type='button' bsPrefix='btn'  className="btn-bears-dark" onClick={() => clearFilter()}>Clear Filters</Button>
                </div>
            </div>
        </div >
    )
}

export default Filters;