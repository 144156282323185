import React from 'react';
import {Accordion, Container, Row} from "react-bootstrap";
import {motion} from "framer-motion";

type Props = {
    currentAccount?: string;
}

const Component: React.FC<Props> = () => {

    return (
        <div className="faq-container overflow-hidden">
            <div className="snow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1536" preserveAspectRatio="xMidYMax slice">
                    <g fill="#FFF" fillOpacity=".15" transform="translate(55 42)">
                        <g id="snow-bottom-layer">
                            <ellipse cx="6" cy="1009.5" rx="6" ry="5.5"/>
                            <ellipse cx="138" cy="1110.5" rx="6" ry="5.5"/>
                            <ellipse cx="398" cy="1055.5" rx="6" ry="5.5"/>
                            <ellipse cx="719" cy="1284.5" rx="6" ry="5.5"/>
                            <ellipse cx="760" cy="1155.5" rx="6" ry="5.5"/>
                            <ellipse cx="635" cy="1459.5" rx="6" ry="5.5"/>
                            <ellipse cx="478" cy="1335.5" rx="6" ry="5.5"/>
                            <ellipse cx="322" cy="1414.5" rx="6" ry="5.5"/>
                            <ellipse cx="247" cy="1234.5" rx="6" ry="5.5"/>
                            <ellipse cx="154" cy="1425.5" rx="6" ry="5.5"/>
                            <ellipse cx="731" cy="773.5" rx="6" ry="5.5"/>
                            <ellipse cx="599" cy="874.5" rx="6" ry="5.5"/>
                            <ellipse cx="339" cy="819.5" rx="6" ry="5.5"/>
                            <ellipse cx="239" cy="1004.5" rx="6" ry="5.5"/>
                            <ellipse cx="113" cy="863.5" rx="6" ry="5.5"/>
                            <ellipse cx="102" cy="1223.5" rx="6" ry="5.5"/>
                            <ellipse cx="395" cy="1155.5" rx="6" ry="5.5"/>
                            <ellipse cx="826" cy="943.5" rx="6" ry="5.5"/>
                            <ellipse cx="626" cy="1054.5" rx="6" ry="5.5"/>
                            <ellipse cx="887" cy="1366.5" rx="6" ry="5.5"/>
                            <ellipse cx="6" cy="241.5" rx="6" ry="5.5"/>
                            <ellipse cx="138" cy="342.5" rx="6" ry="5.5"/>
                            <ellipse cx="398" cy="287.5" rx="6" ry="5.5"/>
                            <ellipse cx="719" cy="516.5" rx="6" ry="5.5"/>
                            <ellipse cx="760" cy="387.5" rx="6" ry="5.5"/>
                            <ellipse cx="635" cy="691.5" rx="6" ry="5.5"/>
                            <ellipse cx="478" cy="567.5" rx="6" ry="5.5"/>
                            <ellipse cx="322" cy="646.5" rx="6" ry="5.5"/>
                            <ellipse cx="247" cy="466.5" rx="6" ry="5.5"/>
                            <ellipse cx="154" cy="657.5" rx="6" ry="5.5"/>
                            <ellipse cx="731" cy="5.5" rx="6" ry="5.5"/>
                            <ellipse cx="599" cy="106.5" rx="6" ry="5.5"/>
                            <ellipse cx="339" cy="51.5" rx="6" ry="5.5"/>
                            <ellipse cx="239" cy="236.5" rx="6" ry="5.5"/>
                            <ellipse cx="113" cy="95.5" rx="6" ry="5.5"/>
                            <ellipse cx="102" cy="455.5" rx="6" ry="5.5"/>
                            <ellipse cx="395" cy="387.5" rx="6" ry="5.5"/>
                            <ellipse cx="826" cy="175.5" rx="6" ry="5.5"/>
                            <ellipse cx="626" cy="286.5" rx="6" ry="5.5"/>
                            <ellipse cx="887" cy="598.5" rx="6" ry="5.5"/>
                        </g>
                    </g>
                    <g fill="#FFF" fillOpacity=".3" transform="translate(65 63)">
                        <g id="snow-top-layer">
                            <circle cx="8" cy="776" r="8"/>
                            <circle cx="189" cy="925" r="8"/>
                            <circle cx="548" cy="844" r="8"/>
                            <circle cx="685" cy="1115" r="8"/>
                            <circle cx="858" cy="909" r="8"/>
                            <circle cx="874" cy="1438" r="8" transform="rotate(180 874 1438)"/>
                            <circle cx="657" cy="1256" r="8" transform="rotate(180 657 1256)"/>
                            <circle cx="443" cy="1372" r="8" transform="rotate(180 443 1372)"/>
                            <circle cx="339" cy="1107" r="8" transform="rotate(180 339 1107)"/>
                            <circle cx="24" cy="1305" r="8" transform="rotate(180 24 1305)"/>
                            <circle cx="8" cy="8" r="8"/>
                            <circle cx="189" cy="157" r="8"/>
                            <circle cx="548" cy="76" r="8"/>
                            <circle cx="685" cy="347" r="8"/>
                            <circle cx="858" cy="141" r="8"/>
                            <circle cx="874" cy="670" r="8" transform="rotate(180 874 670)"/>
                            <circle cx="657" cy="488" r="8" transform="rotate(180 657 488)"/>
                            <circle cx="443" cy="604" r="8" transform="rotate(180 443 604)"/>
                            <circle cx="339" cy="339" r="8" transform="rotate(180 339 339)"/>
                            <circle cx="24" cy="537" r="8" transform="rotate(180 24 537)"/>
                        </g>
                    </g>
                </svg>
            </div>
            <Container>
                <Row className="mt-5">
                    <motion.h1
                        initial={{y: -80, scale: 0.5}}
                        whileInView={{y: 0, scale: 1}}
                        transition={{
                            type: 'circIn',
                            ease: 'anticipate',
                            duration: 1
                        }}
                        className="text-center mb-5">FAQ
                    </motion.h1>
                    <motion.div
                        initial={{x: -100}}
                        whileInView={{x: 1}}
                        transition={{duration: 0.5}}>
                        <Accordion flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h5>Why did we start this project ?</h5></Accordion.Header>
                                <Accordion.Body>
                                    In a world of bots and resellers, the odds of being able to buy a pair of sneakers
                                    are stacked against us. Unlike the good ole days where you could walk into a store
                                    and grab a pair off the shelves, in today’s world you’re at the mercy of the
                                    reseller, the marketplaces, or the raffles.
                                    <br/><br/>
                                    By the time you buy a pair of sneakers on the resell market, it has already
                                    exchanged multiple hands and everyone wants a little piece of the pie. High Seller fees on
                                    platforms such as Grailed, Goat, StockX, Flight Club & Stadium Goods drive sneaker
                                    prices higher.
                                    <br/><br/>
                                    If you don’t want to pay twice the price, you can try your luck on the SNKRS app
                                    where catching an L on the regular has become the norm.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h5>What are the goals of this project ?</h5></Accordion.Header>
                                <Accordion.Body>
                                    One of the goals of this project is to create a marketplace ecosystem for the
                                    community. We’re moving away from venture capitalist-funded companies and want our own Buy
                                    & Sell marketplace with 0% seller and transaction fees.
                                    The launch date for this iOS app is slated to be April 2022.
                                    <br/><br/>
                                    The second goal of this project is to eliminate the middle man.
                                    When shopping on Grailed, Goat, StockX, Flight Club & Stadium Goods,
                                    the prices of sneakers are inflated by as much as 10-25% on each transaction.
                                    As a buyer, we’re never able to directly deal with the vendor.
                                    In today’s world, we believe in full transparency and we don’t want third parties
                                    gatekeeping vendor information. To do so, we’re going to be launching an
                                    NFT Members Only portal that gives you direct access to vendors eliminating the
                                    10-25% markup on each sneaker.
                                    <br/><br/>
                                    The third goal of this project is to allow our NFT holders to access
                                    inventory at near retail prices. As we eliminate and limit parties in each
                                    transaction,
                                    we will be able to drive the prices to their lowest in comparison to publicly
                                    available inventory.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><h5>What are NFTs?</h5></Accordion.Header>
                                <Accordion.Body>
                                    NFTs (or non-fungible tokens) are unique digital tokens that serve as proof of ownership of an asset,
                                    and cannot be replicated. NFTs use blockchain technology, which acts as a digital record of all transactions related to the NFT on a vast network of computers.
                                    While NFTs can be used to represent physical assets, like property or artwork, the majority of NFTs represent collectible digital assets like digital
                                    artwork, music, photos, videos, or even virtual plots of land in video games!
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h5>What Is An NFT Project With Utility?</h5></Accordion.Header>
                                <Accordion.Body>
                                    NFT Projects with utility are NFTs that are more than just Artwork.
                                    Utility NFTs are digital blockchain token assets and generative art that creates and assigns a purpose to own the NFT art collection.
                                    Through the ownership of the Utility NFT, the holders could gain access to an exclusive membership, events, merchandise, discounts, and more.
                                    <br/><br/>
                                    Today Utility NFTs are the most valuable NFT Types that you could invest your cryptocurrency in because they provide the NFT art with
                                    added value making the NFTs better assets and value for tokens.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header><h5>How many NFTs will be available ?</h5></Accordion.Header>
                                <Accordion.Body>
                                    Our aim is to keep our members' community very tight-knit.
                                    We will be limiting the NFT launch to only 5,555.
                                    There will be 5 unique bear design NFTs inspired by some of the most memorable
                                    basketball
                                    moments involving our favorite greatest of all-time players.
                                    Each design will be available to mint 1,111 times on the ETH ERC-1155 blockchain.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </motion.div>
                </Row>
            </Container>
        </div>
    )
}

export default Component;