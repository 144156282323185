import axios from "axios";
import { User } from '../models/user';
import { AXIOS_CONFIG } from "./api-constants";

interface BaseApiResponse {
    success: boolean;
    message: string;
}

interface ValidateCouponResponse extends BaseApiResponse {
}

interface MarkAsExpiredResponse extends BaseApiResponse {
}

interface GenerateCouponResponse extends BaseApiResponse {
    body: {
        code: string
    }
}

interface AccountConnectResponse extends BaseApiResponse {
    data: {
        token: string, 
        user: User
    }
}

interface CheckTokenResponse extends BaseApiResponse {
    data: {
        hasTokens: boolean, 
        allowOrder: boolean
    }
}
interface TokenHostoryResponse extends BaseApiResponse {
    data: {
        address: Array<string>, 
    }
}
export const generateCoupon = async (token: string): Promise<GenerateCouponResponse> => {
    const requestConfig = Object.assign({}, AXIOS_CONFIG());
    const http = axios.create(requestConfig);
    let response = await http.get<GenerateCouponResponse>(`/api/coupon/create/${token}`, {
        validateStatus: (status: number) => {
            return status === 200 || status === 404;
        }
    });
    if (response.status === 404) {
       throw new Error("Invalid token for generate the access code");
    }
    if (response.status !== 200) {
        throw new Error("Unable to generate the access code");
    }
    return response.data;
}

export const validateCoupon = async (couponCode: string): Promise<ValidateCouponResponse> => {
    const requestConfig = Object.assign({}, AXIOS_CONFIG());
    const http = axios.create(requestConfig);
    let response = await http.get<ValidateCouponResponse>(`/api/coupon/validate/${couponCode}`);
    if (response.status !== 200) {
        throw new Error("Unable to validate access code");
    }
    return response.data;
}

export const markAsExpired = async (couponCode: string): Promise<MarkAsExpiredResponse> => {
    const requestConfig = Object.assign({}, AXIOS_CONFIG());
    const http = axios.create(requestConfig);
    let response = await http.post<MarkAsExpiredResponse>(`/api/coupon/mark-as-expire`, {
        "couponCode": couponCode
    });
    if (response.status !== 200) {
        throw new Error("Unable to mark the access code as expired");
    }
    return response.data;
}

export const checkAccountConnect = async (walletId: string): Promise<AccountConnectResponse> => {
    const requestConfig = Object.assign({}, AXIOS_CONFIG());
    const http = axios.create(requestConfig);
    let response = await http.post<AccountConnectResponse>(`/api/account/connectWallet`, {
        walletId: walletId
    });
    if (response.status !== 200) {
        throw new Error("Unable to mark the access code as expired");
    }
    return response.data;
}

export const checkTokenAPI = async (walletId?: string, token?:string): Promise<CheckTokenResponse> => {
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);
    let response = await http.get<CheckTokenResponse>(`/api/token/exits/` + walletId);
    if (response.status !== 200) {
        throw new Error("Unable to mark the access code as expired");
    }
    return response.data;
}

export const tokenTransferHistoryAPI = async (walletId?: string, token?:string): Promise<TokenHostoryResponse> => {
    const requestConfig = Object.assign({}, AXIOS_CONFIG(token));
    const http = axios.create(requestConfig);    
    let response = await http.get<TokenHostoryResponse>(`/api/token/trasfer-history/` + walletId);
    if (response.status !== 200) {
        throw new Error("Unable to mark the access code as expired");
    }
    return response.data;
}
